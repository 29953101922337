import React from "react";

const EDTech = () => {
  return (
    <>
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M64.1673 48.8249V13.6207C64.1673 10.1207 61.309 7.5249 57.8382 7.81657H57.6632C51.5382 8.34157 42.234 11.4624 37.0423 14.7291L36.5465 15.0499C35.7007 15.5749 34.3007 15.5749 33.4548 15.0499L32.7257 14.6124C27.534 11.3749 18.259 8.28323 12.134 7.7874C8.66315 7.49573 5.83398 10.1207 5.83398 13.5916V48.8249C5.83398 51.6249 8.10898 54.2499 10.909 54.5999L11.7548 54.7166C18.084 55.5624 27.8548 58.7707 33.4548 61.8332L33.5715 61.8916C34.359 62.3291 35.6132 62.3291 36.3715 61.8916C41.9715 58.7999 51.7715 55.5624 58.1298 54.7166L59.0923 54.5999C61.8923 54.2499 64.1673 51.6249 64.1673 48.8249Z"
          stroke="#EB2027"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35 16.0125V59.7625"
          stroke="#EB2027"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.6035 24.7625H16.041"
          stroke="#EB2027"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.791 33.5125H16.041"
          stroke="#EB2027"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default EDTech;
