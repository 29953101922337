import React from "react";

const QueteIcon = () => {
  return (
    <>
      <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.9907 26.5734C13.0389 26.58 11.1218 27.0923 9.42366 28.0608C11.8144 21.8308 15.7797 16.3373 20.927 12.1239C21.2215 11.8798 21.4654 11.5796 21.6447 11.2405C21.8239 10.9015 21.9351 10.5302 21.9719 10.1479C22.0086 9.76562 21.9702 9.37977 21.8589 9.01241C21.7475 8.64505 21.5653 8.30337 21.3228 8.00689C21.0803 7.7104 20.7821 7.4649 20.4453 7.28442C20.1085 7.10394 19.7398 6.992 19.36 6.95501C18.9803 6.91801 18.597 6.95668 18.2321 7.0688C17.8672 7.18092 17.5278 7.36429 17.2333 7.60846C6.67973 16.241 1.9834 28.1936 1.9834 35.6043C2.00158 38.2246 2.77721 40.7828 4.21558 42.9667C5.65395 45.1506 7.69282 46.8656 10.0833 47.9023C11.6303 48.6568 13.3245 49.0559 15.0434 49.071C16.5564 49.145 18.0686 48.9089 19.4883 48.377C20.908 47.8452 22.2056 47.0285 23.3024 45.9767C24.3992 44.9249 25.2724 43.6597 25.869 42.258C26.4657 40.8563 26.7733 39.3472 26.7733 37.8222C26.7733 36.2972 26.4657 34.7881 25.869 33.3864C25.2724 31.9846 24.3992 30.7195 23.3024 29.6677C22.2056 28.6158 20.908 27.7992 19.4883 27.2673C18.0686 26.7354 16.5564 26.4993 15.0434 26.5734H14.9907Z"
          fill="#E6F0FF"
        />
        <path
          d="M44.766 26.6565C42.8098 26.6607 40.8879 27.1724 39.1871 28.1419C41.582 21.9268 45.5453 16.4424 50.6886 12.2263C51.0105 11.9935 51.2815 11.6971 51.485 11.3553C51.6884 11.0134 51.82 10.6335 51.8716 10.2387C51.9232 9.84398 51.8938 9.44282 51.7851 9.05992C51.6763 8.67703 51.4907 8.32051 51.2395 8.01229C50.9883 7.70408 50.6769 7.4507 50.3244 7.26774C49.972 7.08479 49.5859 6.97614 49.1901 6.94846C48.7942 6.92079 48.3968 6.97469 48.0225 7.10684C47.6482 7.23899 47.3047 7.44659 47.0134 7.71688C36.4373 16.3378 31.731 28.2746 31.731 35.6753C31.7435 38.2625 32.4981 40.7913 33.9047 42.9598C35.3112 45.1282 37.3103 46.8446 39.663 47.9039C41.2526 48.6794 42.9984 49.0787 44.766 49.071C46.2822 49.1449 47.7976 48.9092 49.2203 48.378C50.643 47.8468 51.9434 47.0313 53.0425 45.9809C54.1417 44.9304 55.0168 43.667 55.6147 42.2671C56.2126 40.8673 56.5209 39.3602 56.5209 37.8372C56.5209 36.3143 56.2126 34.8072 55.6147 33.4073C55.0168 32.0075 54.1417 30.744 53.0425 29.6936C51.9434 28.6432 50.643 27.8276 49.2203 27.2964C47.7976 26.7653 46.2822 26.5295 44.766 26.6034V26.6565Z"
          fill="#E6F0FF"
        />
      </svg>
    </>
  );
};

export default QueteIcon;
