import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import imageIUB from "./img/iub.png";
const TrainingAndResearchExp = () => {
  return (
    <div className="teaching-main-area-wrap">
      <Container>
        <div className="teaching-section-area-wrap">
          <div className="teaching-section-title common-title mb-4">
            <h2>Teaching and Research Experience</h2>
            {/* <p>
              Helped these brands create better values, and make signature
              impact
            </p> */}
          </div>
          <div className="teaching-and-research-content">
            <Row>
              <Col lg="6" className="mb-3">
                <div className="teaching-and-research-content-item">
                  <img src={imageIUB} alt="" />
                  <h4>Adjunct faculty</h4>
                  <h6>School of business</h6>
                  <h6>2013 - 2016</h6>
                  <p>
                    Taught the courses at Undergrad and Graduate Level: <br />{" "}
                    Manpower Planning and Forecasting (HRM 360);
                    <br /> Organizational Behavior( MGT 301);
                    <br /> Principles of Management (MGT 201);
                    <br />
                    Quantitative Applications of Management (MGT 340);
                    <br />
                    Introduction to Human Resource Management (HRM 301);
                    <br />
                    Contemporary perspectives of Management;
                    <br /> & Management Science.(MGT 360)
                  </p>
                </div>
              </Col>{" "}
              <Col lg="6" className="mb-3">
                <div className="teaching-and-research-content-item">
                  <svg viewBox="0 0 121.4 146.5">
                    <g>
                      <polygon
                        points="27,0 23.9,3.4 20.8,0 0,0 0,21 121.4,21 121.4,0 	"
                        fill="#012D73"
                      />
                      <path
                        d="M0.2,25.2c0,0-2.2,30.2,12.3,63.1C16.6,74.9,28.9,47,62.1,36.4c0,0-35.2,19.6-35.9,77.8l0.1,0
		c0.1-0.2,0.1-0.2,0.1-0.4c5.2-17.3,24.1-68.2,67.3-78.8c-48.5,30.7-51.3,85.2-51.2,96.6v0.1c2.2-7.9,18.8-63.2,59.9-76.3
		c0,0-35.2,21-44.6,87c0,0-0.6,4.2-0.6,4.2c0.8-0.5,2.5-1.7,4.4-3C126.8,98,121.1,25.2,121.1,25.2H0.2z"
                        fill="#012D73"
                      />
                    </g>
                  </svg>
                  <h4>Course Developer</h4>
                  <h6>Niels Brock</h6>
                  <h6>2011 - 2012</h6>
                  <p>
                    Developed bachelor level courses, contents and relevant
                    assessment methods for brock online academy, Niels Brock
                    Business College; <span />
                    Courses I developed in coordination with the project
                    manager, ebrock are: <span /> 1. 305: Dimensions in Human
                    resource Management <br /> 2. 405: Cases in Management.
                    <span />
                    Skills: Account Management · Microsoft Excel · Training
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TrainingAndResearchExp;
