/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import Button from "../Common/Button";
import WorkWithShakib from "../Common/WorkWithShakib";
import { majoreBrand } from "../FackData/majorBrand";

const MajorBrand = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="major-brand-main-area-wrap">
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        className="modal-main-area-wrap"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WorkWithShakib handleClose={handleClose} />
        </Modal.Body>
      </Modal>
      <Container>
        <div className="major-brand-inner-area-wrap">
          <div className="major-brand-title common-title">
            <h2>Major Brand worked with</h2>
            <p>
              Helped these brands create better values, and make signature
              impact
            </p>
          </div>
          <div className="major-brand-content-area-wrap">
            <div className="major-brand-content-area">
              <Marquee speed={10}>
                {majoreBrand.map((item, idx) => (
                  <img key={idx} src={item.logo} alt="" />
                ))}
              </Marquee>
            </div>

            <div className="hero-button-bottom-wrap">
              <a onClick={handleShow}>
                <Button>Work With Nazmus</Button>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MajorBrand;
