import React from "react";

const SIcon6 = () => {
  return (
    <>
      <svg
        width="212"
        height="316"
        viewBox="0 0 212 316"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M105.896 316C85.7855 316 67.7143 311.289 51.6823 301.866C35.7908 292.444 23.2043 279.365 13.9226 262.63C4.64086 245.754 0 226.277 0 204.198V116.021C0 92.1139 4.64086 71.5113 13.9226 54.2136C23.2043 36.7753 36.283 23.4152 53.1589 14.1335C70.0347 4.71116 89.8638 0 112.646 0C127.413 0 141.476 2.17979 154.836 6.53938C168.196 10.899 180.431 18.2822 191.541 28.6889L150.617 69.6128C145.554 64.5501 139.718 60.6124 133.108 57.7997C126.498 54.9871 119.678 53.5808 112.646 53.5808C101.677 53.5808 92.1139 56.1824 83.9573 61.3858C75.9413 66.4486 69.6831 73.1286 65.1829 81.4259C60.8233 89.5825 58.6435 98.3017 58.6435 107.583V152.726L48.9399 142.179C57.6591 133.319 67.7143 126.569 79.1055 121.928C90.6373 117.146 102.521 114.756 114.756 114.756C133.882 114.756 150.687 119.045 165.172 127.623C179.798 136.202 191.189 147.945 199.346 162.852C207.643 177.759 211.792 194.775 211.792 213.901C211.792 233.027 206.94 250.325 197.236 265.794C187.533 281.123 174.665 293.358 158.633 302.499C142.601 311.5 125.022 316 105.896 316ZM105.896 261.154C114.615 261.154 122.561 259.044 129.733 254.825C137.046 250.606 142.812 244.911 147.031 237.738C151.39 230.566 153.57 222.62 153.57 213.901C153.57 205.182 151.461 197.236 147.242 190.064C143.023 182.751 137.327 176.985 130.155 172.766C122.983 168.407 115.037 166.227 106.318 166.227C97.5986 166.227 89.5826 168.407 82.2697 172.766C75.0975 176.985 69.3316 182.751 64.972 190.064C60.753 197.236 58.6435 205.182 58.6435 213.901C58.6435 222.339 60.753 230.144 64.972 237.316C69.1909 244.489 74.8865 250.255 82.0588 254.614C89.231 258.974 97.1767 261.154 105.896 261.154Z"
          fill="#9E9E9E"
          fillOpacity="0.16"
        />
      </svg>
    </>
  );
};

export default SIcon6;
