import React, { useState } from "react";
import { Container } from "react-bootstrap";
import ResponsiveNavItems2 from "./ResponsiveNavItems2";

const HeaderCaseStudies = () => {
  const [navToggle, setNavToggle] = useState(false);
  return (
    <div className="header-main-area-wrap" id="hero">
      <Container>
        {/* <div className="header-top-main-area">
          <ul>
            <li>shakib@maacsolutions.com</li>
            <li>+123 456 789</li>
          </ul>
        </div> */}
        <div className="header-bottom-area-wrap">
          <div className="header-logo-area-left">
            {/* <Link>
              <h3>Logo</h3>
            </Link> */}
          </div>
          <div className="header-navbar-items-area">
            <div className="nav-toggle-button-wrap">
              <button
                onClick={() => setNavToggle(!navToggle)}
                className={
                  navToggle
                    ? "nav-toggle-menu show-nav-button"
                    : "nav-toggle-menu hide-nav-button"
                }
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
            {/* <DesktopNavItems2 /> */}
            <ResponsiveNavItems2
              toggleClass={
                navToggle ? "nav-toggle-menu-show" : "nav-toggle-menu-hide"
              }
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeaderCaseStudies;
