import React from "react";

const PeopleIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0004 5.96602C14.9504 5.95769 14.8921 5.95769 14.8421 5.96602C13.6921 5.92435 12.7754 4.98268 12.7754 3.81602C12.7754 2.62435 13.7337 1.66602 14.9254 1.66602C16.1171 1.66602 17.0754 2.63268 17.0754 3.81602C17.0671 4.98268 16.1504 5.92435 15.0004 5.96602Z"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1422 12.0328C15.2839 12.2245 16.5422 12.0245 17.4255 11.4328C18.6005 10.6495 18.6005 9.36615 17.4255 8.58282C16.5339 7.99115 15.2589 7.79115 14.1172 7.99115"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.97344 5.96602C5.02344 5.95769 5.08177 5.95769 5.13177 5.96602C6.28177 5.92435 7.19844 4.98268 7.19844 3.81602C7.19844 2.62435 6.24011 1.66602 5.04844 1.66602C3.85677 1.66602 2.89844 2.63268 2.89844 3.81602C2.90677 4.98268 3.82344 5.92435 4.97344 5.96602Z"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83255 12.0328C4.69088 12.2245 3.43255 12.0245 2.54922 11.4328C1.37422 10.6495 1.37422 9.36615 2.54922 8.58282C3.44089 7.99115 4.71588 7.79115 5.85755 7.99115"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0004 12.1926C9.95039 12.1842 9.89206 12.1842 9.84206 12.1926C8.69206 12.1509 7.77539 11.2092 7.77539 10.0426C7.77539 8.85091 8.73372 7.89258 9.92539 7.89258C11.1171 7.89258 12.0754 8.85925 12.0754 10.0426C12.0671 11.2092 11.1504 12.1592 10.0004 12.1926Z"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.57461 14.8168C6.39961 15.6001 6.39961 16.8835 7.57461 17.6668C8.90794 18.5585 11.0913 18.5585 12.4246 17.6668C13.5996 16.8835 13.5996 15.6001 12.4246 14.8168C11.0996 13.9335 8.90794 13.9335 7.57461 14.8168Z"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PeopleIcon;
