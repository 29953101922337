import React from "react";

const SIcon2 = () => {
  return (
    <>
      <svg
        width="220"
        height="316"
        viewBox="0 0 220 316"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.1"
          d="M0 315.57V264.838L131.505 149.616C141.375 141.018 148.342 132.921 152.406 125.325C156.47 117.73 158.502 110.636 158.502 104.044C158.502 94.585 156.543 86.2014 152.624 78.8925C148.705 71.4404 143.189 65.6363 136.077 61.4803C128.965 57.1809 120.619 55.0313 111.039 55.0313C101.023 55.0313 92.1694 57.3242 84.4765 61.9102C76.9288 66.3528 71.0503 72.3002 66.8409 79.7524C62.6316 87.0612 60.6721 95.015 60.9624 103.614H0C0 82.4036 4.71733 64.0599 14.152 48.5823C23.5867 33.1047 36.7226 21.1383 53.5598 12.683C70.3971 4.22767 89.9921 0 112.345 0C132.811 0 151.1 4.44262 167.211 13.3279C183.468 22.0698 196.241 34.3229 205.53 50.0871C214.82 65.8512 219.465 84.1233 219.465 104.903C219.465 120.238 217.288 132.992 212.933 143.167C208.579 153.199 202.119 162.514 193.556 171.113C184.992 179.568 174.469 189.17 161.986 199.918L77.0739 273.437L72.284 260.539H219.465V315.57H0Z"
          fill="#9E9E9E"
        />
      </svg>
    </>
  );
};

export default SIcon2;
