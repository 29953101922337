import IconFive from "../assets/images/icon/achievements/IconFive";
import IconFour from "../assets/images/icon/achievements/IconFour";
import IconOne from "../assets/images/icon/achievements/IconOne";
import IconThree from "../assets/images/icon/achievements/IconThree";
import IconTwo from "../assets/images/icon/achievements/IconTwo";
import CalibraxIcon from "../assets/images/icon/CalibraxIcon";
import FieldX from "../assets/images/icon/FieldX";
import Merp from "../assets/images/icon/Merp";
import SalesX from "../assets/images/icon/SalesX";
import Somriddhi from "../assets/images/icon/Somriddhi";
import SIcon1 from "../assets/images/icon/stories/SIcon1";
import SIcon2 from "../assets/images/icon/stories/SIcon2";
import SIcon3 from "../assets/images/icon/stories/SIcon3";
import SIcon4 from "../assets/images/icon/stories/SIcon4";
import SIcon5 from "../assets/images/icon/stories/SIcon5";
import SIcon6 from "../assets/images/icon/stories/SIcon6";
import Xelerator from "../assets/images/icon/Xelerator";
import iamge1 from "../assets/images/portfolio/item-1.png";
import iamge2 from "../assets/images/portfolio/item-2.png";
import iamge3 from "../assets/images/portfolio/item-3.png";
import iamge4 from "../assets/images/portfolio/item-4.png";
export const portfolioData = [
  {
    title: "Business Coaching",
    content:
      "You enter into any new area of create science, with a baffle language.",
    image: iamge1,
    link: "",
  },
  {
    title: "Team Packages",
    content:
      "You enter into any new area of create science, with a baffle language.",
    image: iamge2,
    link: "",
  },
  {
    title: "Workshops & Retreats",
    content:
      "You enter into any new area of create science, with a baffle language.",
    image: iamge3,
    link: "",
  },
  {
    title: "Employee Training",
    content:
      "You enter into any new area of create science, with a baffle language.",
    image: iamge4,
    link: "",
  },
];
export const achivementData = [
  {
    id: 1,
    icon: <IconOne />,
    count: 15,
    symble: "+",
    title: "Years of Experience",
  },
  {
    id: 2,
    icon: <IconTwo />,
    count: 7,
    symble: "+",
    title: "Industry Exposure",
  },
  {
    show: 3,
    icon: <IconThree />,
    count: 3,
    symble: "",
    title: "Major Brand Launch",
  },
  {
    show: 4,
    icon: <IconFour />,
    count: 15,
    symble: "+",
    title: "Digital Platforms",
  },
  {
    show: 5,
    icon: <IconFive />,
    count: 200,
    symble: "h",
    title: "Hours Sales Training",
  },
];

export const successStories = [
  {
    id: 1,
    logo: <FieldX />,
    idxIcon: <SIcon1 />,
    title: "FieldX",
    content:
      "<p>Precisely, a data extraction tool offers multidimensional enterprise support in relation to data capturing, data curating, GEO tagging, and finally descending to a dynamic data vs. utilization. First-of-its-kind in Bangladesh as such available both in apps...</p>",
    link: "https://field-x.org/",
    demoLink: "https://maacsolutions.com/course-demo-request?coursename=FieldX",
    count: 100000,
  },
  {
    id: 2,
    logo: <Xelerator />,
    idxIcon: <SIcon2 />,
    title: "Xelerator",
    content:
      "<p>In this era of New normal, what matters most is the data connecting the dots to form a compelling story for brands and companies. This tool essentially integrates you with those compelling brand insights and stories which otherwise ignored or unsurfaced. Here is your best opportunity to integrate with the data hub that not only gives you insights but also predicts the next move for your brand.</p>",
    link: "https://xelerator.org/",
    demoLink:
      "https://maacsolutions.com/course-demo-request?coursename=Xelerator",
    count: 1000000,
  },
  {
    id: 3,
    logo: <Merp />,
    idxIcon: <SIcon3 />,
    title: "Merp",
    visibleClass: "showData",
    content:
      "<p>SAAS-based ERP products aimed at SMEs and MSMEs, mainly at small scale, to integrate into an ecosystem of transparency and sustainability. This tool will eventually embark upon a grid of information to make SMEs pass the hurdles with data science in disguise.</p>",
    link: "https://erpxbd.com/",
    demoLink: "https://maacsolutions.com/course-demo-request?coursename=mERP",
    count: 10000,
  },
  {
    id: 4,
    logo: <Somriddhi />,
    idxIcon: <SIcon4 />,
    title: "Somriddhi",
    content:
      "<p>Smart learning and development tool provides specialized training solutions for individuals and teams. On top of it, one can have real-life simulation, case-based assessments aimed to serve industries like FMCGs, CPGs, Building materials, etc.</p>",
    visibleClass: "showData",
    link: "https://somriddhi.com/",
    demoLink: "https://somriddhi.com/",
    count: 1000000,
  },
  {
    id: 5,
    logo: <SalesX />,
    idxIcon: <SIcon5 />,
    title: "SalesX",
    content:
      "<p>Companies with diversified products and ranges, higher SKUs always want to have a solution that speaks the mind of the market, translates the competition activities, and drives the business with data-driven logic. This app covers all-in-one from inventory to customer footprints- you name anything, it has it all, mostly appropriate for FMCGs, CPGs, Pharmaceuticals, E-commerce, etc.</p>",
    visibleClass: "showData",
    link: "https://sales-x.net/",
    demoLink: "https://maacsolutions.com/course-demo-request?coursename=SalesX",
    count: 10000000,
  },
  {
    id: 6,
    logo: <CalibraxIcon />,
    idxIcon: <SIcon6 />,
    title: "CourseWare",
    content:
      "<p>This is your very own learning console, you take the charge of applied learning. The course once set for you, you take decisions, and see the real impact. Once you decide, you can undo, unlike in real world- a sense to form in your cerebra.</p>",
    visibleClass: "showData",
    link: "https://calibrax.org/",
    demoLink:
      "https://maacsolutions.com/course-demo-request?coursename=calibraX",
    count: 9999,
  },
];
export const postData = [
  {
    id: "1",
    icon: "1655195599332-1.jpg",
    title: "Why still tri state matters in RE",
    content:
      "  Solutions for retail innovation in point sector seems late, but surprisingly market makes the move...",
    author: "Nazmus Shakib",
    link: "/insight-post",
    date: "Jan 1 2022",
  },
  {
    id: "2",
    icon: "1655195779414-2.jpg",
    title: "Categorization for better results",
    content: `  Earning vs. Burning Game teases entrepreneurs to look
    for better options when trying to make a business big.
    Well, MAAC has been trying...`,
    author: "Nafisuzzaman",
    link: "/insight-post2",
    date: "Jan 2 2022",
  },
  {
    id: "3",
    icon: "1655195842234-3.png",
    title: "Transcendence: Blueprint for next level loyalty",
    content: ` Data has been the new currency when we all allow us to
    become a factor of a market, willingly or unwittingly.
    Can this data...`,
    author: "Nazmus Shakib",
    // link: "/insights",
    link: "/insight-post-3",
    date: "Jan 3 2022",
  },
  {
    id: "4",
    icon: "1655196438961-4.png",
    title: "Startup cell and growth",
    content: ` Data has been the new currency when we all allow us to
    become a factor of a market, willingly or unwittingly.
    Can this data...`,
    author: "Nazmus Shakib",
    link: "/insight-post-4",
    date: "Jan 4 2022",
  },
  {
    id: "5",
    icon: "1655196496480-5.png",
    title: "Data index drives decision",
    content: ` Data has been the new currency when we all allow us to
    become a factor of a market, willingly or unwittingly.
    Can this data...`,
    author: "Nazmus Shakib",
    link: "/insight-post-5",
    date: "Jan 5 2022",
  },

  {
    id: "6",
    icon: "1655196533404-6.png",
    title: "Brand positioning exercise: RANCON",
    content: ` Data has been the new currency when we all allow us to
    become a factor of a market, willingly or unwittingly.
    Can this data...`,
    author: "Nazmus Shakib",
    link: "/insight-post-6",
    date: "Jan 6 2022",
  },
  {
    id: "7",
    icon: "1655196575416-7.png",
    title: "RE Sell out: ICONOMY",
    content: ` Data has been the new currency when we all allow us to
    become a factor of a market, willingly or unwittingly.
    Can this data...`,
    author: "Nazmus Shakib",
    link: "/insight-post-7",
    date: "Jan 7 2022",
  },
  // {
  //   id: "8",
  //   icon: "1655197107497-8.png",
  //   title: "Business case: Lamborghini in Myanmar",
  //   content: ` Data has been the new currency when we all allow us to
  //   become a factor of a market, willingly or unwittingly.
  //   Can this data...`,
  //   author: "Nazmus Shakib",
  //   link: "/insight-post-8",
  //   date: "Jan 8 2022",
  // },
  // {
  //   id: "9",
  //   icon: "1655197140888-9.png",
  //   title: "Two wheeler market synopsis: 2018-19",
  //   content: ` Data has been the new currency when we all allow us to
  //   become a factor of a market, willingly or unwittingly.
  //   Can this data...`,
  //   author: "Nazmus Shakib",

  //   link: "/insight-post-9",
  //   date: "Jan 9 2022",
  // },

  // {
  //   id: "10",
  //   icon: "1655197177892-10.png",
  //   title: "MAAC LMS: Enabling & Empowering digital learning",
  //   content: ` Data has been the new currency when we all allow us to
  //   become a factor of a market, willingly or unwittingly.
  //   Can this data...`,
  //   author: "Nafisuzzaman",
  //   link: "/insight-post-10",
  //   date: "Jan 10 2022",
  // },
  // {
  //   id: "11",
  //   icon: "1655197220344-11.png",
  //   title: "Country Investment Comparator – 740",
  //   content: ` Data has been the new currency when we all allow us to
  //   become a factor of a market, willingly or unwittingly.
  //   Can this data...`,
  //   author: "Nazmus Shakib",
  //   link: "/insights",
  //   date: "Jan 11 2022",
  // },
  // {
  //   id: "12",
  //   icon: "1655197257699-12.png",
  //   title: "Logistics planning: a rare case for CEMENT manufacturers",
  //   content: ` Data has been the new currency when we all allow us to
  //   become a factor of a market, willingly or unwittingly.
  //   Can this data...`,
  //   author: "Nazmus Shakib",
  //   link: "/insight-post-12",
  //   date: "Jan 12 2022",
  // },
  // {
  //   id: "13",
  //   icon: "1655197298207-13.png",
  //   title: "RTM consultancy : a leap of change in Cement industry",
  //   content: `A full package solution for DX with the current universe to make sustainable impacts, and growth there is just derivatives to cash in.`,
  //   author: "Nazmus Shakib",
  //   link: "https://www.youtube.com/embed/1kNyvf4YsSU",
  // },
];
