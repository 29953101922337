/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import TruncateMarkup from "react-truncate-markup";
import { Autoplay, Navigation } from "swiper";
import "swiper/css/autoplay";
import "swiper/css/lazy";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Button from "../Common/Button";
import WorkWithShakib from "../Common/WorkWithShakib";
import { postData } from "../FackData";
const PortfolioTwoCents = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        className="modal-main-area-wrap"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WorkWithShakib handleClose={handleClose} />
        </Modal.Body>
      </Modal>
      <div className="portfolio-main-area-wrap" id="Blog">
        <Container>
          <div className="portfolio-header common-title">
            <h2>Two cents</h2>
            <p>Worldview from the marketer's sense</p>
          </div>

          <div className="portfolio-items-wrap">
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              spaceBetween={30}
              slidesPerView={4}
              pagination={true}
              autoplay={true}
              loop={true}
              navigation={false}
              breakpoints={{
                300: {
                  // width: 576,
                  slidesPerView: 1,
                },
                576: {
                  // width: 576,
                  slidesPerView: 2,
                },
                768: {
                  // width: 768,
                  slidesPerView: 3,
                },
                992: {
                  // width: 768,
                  slidesPerView: 4,
                },
              }}
            >
              {postData.map((item, idx) => (
                <SwiperSlide key={idx}>
                  {" "}
                  <a
                    href={`https://maacsolutions.com${item.link}`}
                    className="portfolio-single-item"
                    key={idx}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={`https://staging-ddc.sgp1.digitaloceanspaces.com/${item.icon}`}
                      alt=""
                    />
                    <div className="portfolio-single-item-bottom-content">
                      <h4>{item.title}</h4>
                      <TruncateMarkup lines={3}>
                        <p>{item.content}</p>
                      </TruncateMarkup>
                    </div>
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="hero-button-bottom-wrap">
              <a onClick={handleShow}>
                <Button>Work With Nazmus</Button>
              </a>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default PortfolioTwoCents;
