import React from "react";

const SIcon4 = () => {
  return (
    <>
      <svg
        width="227"
        height="316"
        viewBox="0 0 227 316"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M137.372 316V266.844H0V211.106L99.6278 0H168.094L68.4667 211.106H137.372V132.106H197.939V211.106H226.028V266.844H197.939V316H137.372Z"
          fill="#9E9E9E"
          fillOpacity="0.16"
        />
      </svg>
    </>
  );
};

export default SIcon4;
