import React from "react";

const Merp = () => {
  return (
    <>
      <svg
        width="148"
        height="36"
        viewBox="0 0 148 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.0719 17.4297V35.9923H17.1121V19.4773C17.1121 18.7578 17.0202 18.1186 16.8403 17.5637C16.6604 17.0049 16.3734 16.5456 15.9753 16.1858C15.5773 15.826 15.0759 15.5581 14.4635 15.3744C13.8512 15.1945 13.1125 15.1027 12.2513 15.1027C11.4973 15.1027 10.8199 15.2022 10.2267 15.4012C9.63343 15.6002 9.20859 15.8069 8.95981 16.0212V35.9885H0V10.9538C0.646821 10.5941 1.54625 10.2343 2.69828 9.87451C3.85031 9.51474 5.12481 9.18176 6.52945 8.87557C7.93408 8.56939 9.35403 8.31678 10.7931 8.12159C12.2322 7.92257 13.545 7.82306 14.7314 7.82306C17.1427 7.82306 19.1903 8.11011 20.882 8.68804C22.5737 9.26597 23.8597 10.2534 24.74 11.6581C25.6317 13.0589 26.0719 14.984 26.0719 17.4297ZM17.1121 12.9517C18.2258 11.9451 19.6228 11.0648 21.2953 10.307C22.9679 9.55302 24.7208 8.9483 26.5579 8.5005C28.3912 8.0527 30.1021 7.82689 31.6828 7.82689C34.094 7.82689 36.1454 8.11394 37.8333 8.69186C39.525 9.26979 40.811 10.2572 41.6913 11.6619C42.5715 13.0665 43.0117 14.9917 43.0117 17.4373V36H34.0519V19.485C34.0519 18.7654 33.96 18.1263 33.7801 17.5713C33.6003 17.0125 33.3132 16.5532 32.9152 16.1935C32.5171 15.8337 32.0157 15.5658 31.4034 15.3821C30.791 15.2022 30.0561 15.1103 29.1912 15.1103C28.4372 15.1103 27.7597 15.2175 27.1665 15.4356C26.5733 15.65 26.1484 15.9026 25.8996 16.1896L17.1121 12.9517Z"
          fill="#E5414C"
        />
        <path
          d="M48.7373 35.9962V-1.14441e-05H73.8294V7.448H58.5583V14.2492H71.7243V21.6972H58.5047V28.552H73.8294V36H48.7373V35.9962Z"
          fill="#0B2E4E"
        />
        <path
          d="M79.498 35.9962V-1.14441e-05H97.632C101.804 -1.14441e-05 105.095 0.964483 107.507 2.88581C109.918 4.81096 111.123 7.71592 111.123 11.6007C111.123 13.078 110.825 14.5707 110.232 16.0787C109.638 17.5905 108.739 18.9109 107.533 20.0476C106.328 21.1805 104.808 21.946 102.975 22.3402L101.896 19.0487L113.068 35.9923H100.548L91.8604 20.5031L96.124 24.0663H89.2693V35.9923H79.498V35.9962ZM89.2654 17.0546H96.2274C97.4483 17.0546 98.474 16.6757 99.3046 15.9217C100.131 15.1678 100.545 13.8894 100.545 12.0906C100.545 10.5099 100.158 9.33871 99.3849 8.58473C98.6118 7.83074 97.632 7.45183 96.4455 7.45183H89.2693V17.0546H89.2654Z"
          fill="#0B2E4E"
        />
        <path
          d="M127.095 24.6098V35.9962H117.328V-1.14441e-05H134.271C138.481 -1.14441e-05 141.819 0.952998 144.284 2.85902C146.748 4.76503 147.981 7.7695 147.981 11.8724C147.981 15.9371 146.748 19.0755 144.284 21.2915C141.819 23.5037 138.481 24.6098 134.271 24.6098H127.095ZM127.095 17.1618H133.031C133.713 17.1618 134.336 17.0623 134.891 16.8633C135.45 16.6643 135.906 16.3619 136.269 15.9447C136.629 15.5314 136.908 15.0376 137.107 14.4597C137.306 13.8856 137.406 13.235 137.406 12.5154C137.406 11.6887 137.306 10.9692 137.107 10.3568C136.908 9.74441 136.629 9.21624 136.269 8.76461C135.909 8.31681 135.45 7.98383 134.891 7.76567C134.333 7.55134 133.713 7.44035 133.031 7.44035H127.095V17.1618Z"
          fill="#0B2E4E"
        />
      </svg>
    </>
  );
};

export default Merp;
