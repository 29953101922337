import profileImage from "../assets/images/Testimonials/1665935666038.jpg";
import profileImage2 from "../assets/images/Testimonials/EnricoSgrosso.jpg";
import profileImage10 from "../assets/images/Testimonials/Evis.jpg";
import profileImage9 from "../assets/images/Testimonials/nick.jpg";
import profileImage3 from "../assets/images/Testimonials/paolo.jpg";
import profileImage6 from "../assets/images/Testimonials/Partha.jpg";
import profileImage8 from "../assets/images/Testimonials/pat.jpg";
import QueteIcon from "../assets/images/Testimonials/QueteIcon";
import profileImage4 from "../assets/images/Testimonials/shams.jpg";
import profileImage5 from "../assets/images/Testimonials/Shiblee.jpg";
export const testimonialsData = [
  {
    id: "1",
    icon: <QueteIcon />,
    profileImage: profileImage,
    name: "Kaiser C.",
    designation: " Senior Director <br/> Human Resources - Bangladesh",
    content: `Shakib Bhai is a rare breed. He is bright and persuasive, but he is also a class act both personally and professionally. He gets behind the mission he believes in, but won't push something if he knows it's not in the stakeholder's best interest.
      You hardly get to see a marketeer who deeply understands the application of marketing strategy, brand management, communication and at the same has high commercial acumen which makes him confident to bring the desired impact at micro and macro level. Moreover, what makes him more unique is high HIGH sense of humor. You can never get bored when this person is with you.
      In brief, Shakib Bhai is incredible. Not just in his professionalism and demeanor, but in his clear understanding of how to create high impact – both in terms of content and delivery.
      I wish him all the best for everything that he is doing and will do in future. He is a high value asset for any organization!`,
  },
  {
    id: "2",
    icon: <QueteIcon />,
    profileImage: profileImage2,
    name: "Enrico Sgrosso",
    designation: "Project Manager Proposals/Bids ",
    content: `Nazmus is a highly skilled individual, with a strong motivation and passion for what he does.
      He is an excellent team player with outstanding communication and project management skills.`,
  },
  {
    id: "3",
    icon: <QueteIcon />,
    profileImage: profileImage3,
    name: "Paolo Cortelazzi",
    designation: "Global Brand Marketing Manager ",
    content: `I had the pleasure to meet Nazmus along my academic career. I believe that, also thanks to his past experiences, he is very detailed and insightful in what he does, passion and dedication are part of him. Hence, working in projects with him it has been an intensive learning process. His strategic mindset allows Nazmus to bring interesting ideas, especially during case analysis.`,
  },
  {
    id: "4",
    icon: <QueteIcon />,
    profileImage: profileImage4,
    name: "Shams Azad",
    designation: "Business Strategy",
    content: `Shakib is very much detailed in his deliverables..He has the capacity to go deep inside a problem and bring innovative solutions for that which in turn produces better business results...I wish him further success in his coming years`,
  },
  {
    id: "5",
    icon: <QueteIcon />,
    profileImage: profileImage5,
    name: "Shiblee Ahmed",
    designation: "Sales & Marketing Leader",
    content: `I worked with Shakib in BATB in the Trade Marketing & Distribution team as well as in Nokia team where he was the Training Manager for Nokia Academy. In both roles he was very impressive. A hardworking guy with lots of passion and energy. He has been a very good facilitator for the sales & field force team. His TM&D management capabilities are also worth mentioning. I wish Shakib all the best with his new endeavors.`,
  },
  {
    id: "6",
    icon: <QueteIcon />,
    profileImage: profileImage6,
    name: "Partha Bhowmik",
    designation: "Head of Procurement at Avery Dennison",
    content: `Shakib is an incredible marketing talent and an asset to any team. Working with him was an absolute pleasure. He's a rare marketeer in that his stakeholder and project manager skills equal his impressive eye for detail and creative flair. <br />
    His ability to keep calm under pressure and deliver high-quality work on even the of tightest deadlines is unrivalled. Shakib's skill set is wide and varied. Never a task that isn't completed with passion, care and professionalism.`,
  },
  {
    id: "7",
    icon: <QueteIcon />,
    profileImage: "https://maacsolutions.com/static/media/M.e74e644a.jpg",
    name: "Sharif Uddin Md Hassan",
    designation: "Managing Director, MAG",
    content: `Exceeding expectations is almost his second nature and creativity is his art to deliver things in unparalleled way.`,
  },
  {
    id: "8",
    icon: <QueteIcon />,
    profileImage: profileImage8,
    name: "Pat Auger",
    designation: "Associate Professor at Melbourne Business School",
    content: `Master at planning for long term phase and especially his strategic insights at crucial time can make him different in the crowd…”`,
  },

  {
    id: "9",
    icon: <QueteIcon />,
    profileImage: profileImage9,
    name: "Nick Pogrebnyakov",
    designation:
      "Senior ML Engineer at Twitter & Associate Professor at Copenhagen Business School",
    content: `Mr Nazmus skillfully uses facts and analytical reasoning to reach a logical conclusion…while the course is demanding, he has earned the highest grade`,
  },
  {
    id: "10",
    icon: <QueteIcon />,
    profileImage: profileImage10,
    name: "Evis Sinani",
    designation: "Associate Professor at Copenhagen Business School",
    content: `Crafting numerical values to strategic decisions and excavate blue ocean facts from data is his customary expertise in research.
    `,
  },
];
