import React from "react";

const BlackBeltTM = () => {
  return (
    <>
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.7608 38.7334H26.7733V59.7334C26.7733 64.6334 29.4274 65.625 32.6649 61.95L54.7441 36.8667C57.4566 33.8042 56.3191 31.2667 52.2066 31.2667H43.1941V10.2667C43.1941 5.36669 40.5399 4.37503 37.3024 8.05003L15.2233 33.1334C12.5399 36.225 13.6774 38.7334 17.7608 38.7334Z"
          stroke="#EB2027"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default BlackBeltTM;
