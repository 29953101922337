import logo1 from "../assets/images/majorBrand/logo-1.svg";
import logo10 from "../assets/images/majorBrand/logo-10.svg";
import logo11 from "../assets/images/majorBrand/logo-11.svg";
import logo12 from "../assets/images/majorBrand/logo-12.svg";
import logo13 from "../assets/images/majorBrand/logo-13.svg";
import logo14 from "../assets/images/majorBrand/logo-14.svg";
import logo15 from "../assets/images/majorBrand/logo-15.svg";
import logo16 from "../assets/images/majorBrand/logo-16.svg";
import logo17 from "../assets/images/majorBrand/logo-17.svg";
import logo18 from "../assets/images/majorBrand/logo-18.svg";
import logo19 from "../assets/images/majorBrand/logo-19.svg";
import logo2 from "../assets/images/majorBrand/logo-2.svg";
import logo20 from "../assets/images/majorBrand/logo-20.svg";
import logo21 from "../assets/images/majorBrand/logo-21.svg";
import logo22 from "../assets/images/majorBrand/logo-22.svg";
import logo23 from "../assets/images/majorBrand/logo-23.svg";
import logo24 from "../assets/images/majorBrand/logo-24.svg";
import logo25 from "../assets/images/majorBrand/logo-25.svg";
import logo26 from "../assets/images/majorBrand/logo-26.svg";
import logo27 from "../assets/images/majorBrand/logo-27.svg";
import logo28 from "../assets/images/majorBrand/logo-28.svg";
import logo29 from "../assets/images/majorBrand/logo-29.svg";
import logo3 from "../assets/images/majorBrand/logo-3.svg";
import logo30 from "../assets/images/majorBrand/logo-30.svg";
import logo31 from "../assets/images/majorBrand/logo-31.svg";
import logo32 from "../assets/images/majorBrand/logo-32.svg";
import logo33 from "../assets/images/majorBrand/logo-33.svg";
import logo35 from "../assets/images/majorBrand/logo-35.svg";
import logo36 from "../assets/images/majorBrand/logo-36.svg";
import logo37 from "../assets/images/majorBrand/logo-37.svg";
import logo4 from "../assets/images/majorBrand/logo-4.svg";
import logo5 from "../assets/images/majorBrand/logo-5.svg";
import logo6 from "../assets/images/majorBrand/logo-6.svg";
import logo7 from "../assets/images/majorBrand/logo-7.svg";
import logo8 from "../assets/images/majorBrand/logo-8.svg";
import logo9 from "../assets/images/majorBrand/logo-9.svg";
export const majoreBrand = [
  {
    logo: logo1,
  },
  {
    logo: logo2,
  },
  {
    logo: logo3,
  },
  {
    logo: logo4,
  },
  {
    logo: logo5,
  },
  {
    logo: logo6,
  },
  {
    logo: logo7,
  },
  {
    logo: logo8,
  },
  {
    logo: logo9,
  },
  {
    logo: logo10,
  },
  {
    logo: logo11,
  },
  {
    logo: logo12,
  },
  {
    logo: logo13,
  },
  {
    logo: logo14,
  },
  {
    logo: logo15,
  },
  {
    logo: logo16,
  },
  {
    logo: logo17,
  },
  {
    logo: logo18,
  },
  {
    logo: logo19,
  },
  {
    logo: logo20,
  },
  {
    logo: logo21,
  },
  {
    logo: logo22,
  },
  {
    logo: logo23,
  },
  {
    logo: logo24,
  },
  {
    logo: logo25,
  },
  {
    logo: logo26,
  },
  {
    logo: logo27,
  },
  {
    logo: logo28,
  },
  {
    logo: logo29,
  },
  {
    logo: logo30,
  },
  {
    logo: logo31,
  },
  {
    logo: logo32,
  },
  {
    logo: logo33,
  },
  //   {
  //     logo: logo34,
  //   },
  {
    logo: logo35,
  },
  {
    logo: logo36,
  },
  {
    logo: logo37,
  },
];
