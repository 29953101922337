import React from "react";
import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <div className="footer-main-area-wrap">
      <Container>
        <div className="footer-inner-area-wrap">
          <div className="footer-left-copyright-area">
            <p>© 2022 MNShakib</p>
          </div>
          <div className="footer-right-social-area">
            <a
              href="https://www.facebook.com/nazmus.shakib.39"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bx bxl-facebook-square"></i>
            </a>
            <a
              href="https://twitter.com/MdNazmusShakib"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bx bxl-twitter"></i>
            </a>
            <a
              href="https://www.linkedin.com/in/mdshakib/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bx bxl-linkedin-square"></i>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
