import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "../Common/Button";
import { successStories } from "../FackData";

const SuccessStoriesAll = () => {
  const [showData, setShowData] = useState(true);

  return (
    <div id="case">
      <Container>
        <div className="success-stories-inner-area all-stories">
          <div className="success-stories-title">
            <div className="stories-title-left common-title">
              <h2>Knitting Stories</h2>
              <p>Connecting dots in Human, Retail and Technology</p>
            </div>
            <div className="stories-left">
              <Link to="/case-studies">
                <Button>All Case Studies</Button>
              </Link>
            </div>
          </div>
          <div className="success-stories-lists-area-wrap">
            {successStories.map((item, idx) => (
              <div
                key={idx}
                className={
                  showData
                    ? `success-story-single-item ${item.visibleClass}`
                    : `success-story-single-item `
                }
              >
                <div>
                  <div className="success-story-item-header">
                    <div className="story-index">
                      <h3>{item.id}</h3>
                    </div>
                    {item.logo}
                  </div>
                  <div>
                    <h5>{item.title}</h5>
                    <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                  </div>
                </div>
                <div className="story-button-bottom-btn">
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <Button>View Details</Button>
                  </a>
                </div>
              </div>
            ))}
            {showData ? (
              <button
                className="story-show-button-bottom-show-more"
                onClick={() => setShowData(!showData)}
              >
                Show more
              </button>
            ) : (
              <button
                className="story-show-button-bottom-show-more"
                onClick={() => setShowData(!showData)}
              >
                Hide
              </button>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SuccessStoriesAll;
