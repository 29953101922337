import React from "react";

const SIcon5 = () => {
  return (
    <>
      <svg
        width="191"
        height="316"
        viewBox="0 0 191 316"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M81.491 316C65.8877 316 51.2149 312.775 37.4725 306.327C23.8734 299.878 6 287.5 2.25782 279.456C-1.48436 271.412 2.25782 255 0.500267 240.5C-1.25729 226 37.2578 224.424 37.2578 224.424C39.1188 231.733 42.4112 238.039 47.1351 243.342C52.0022 248.644 57.585 252.8 63.8836 255.81C70.3253 258.676 76.9102 260.109 83.6382 260.109C92.5134 260.109 100.601 257.959 107.902 253.66C115.346 249.361 121.215 243.556 125.509 236.248C129.947 228.939 132.166 220.842 132.166 211.956C132.166 203.071 129.947 194.974 125.509 187.665C121.215 180.356 115.346 174.552 107.902 170.253C100.601 165.954 92.5134 163.804 83.6382 163.804C72.7588 163.804 63.8836 165.667 57.0124 169.393C50.1413 172.976 44.9879 176.415 41.5523 179.712L0.5 165.5L0.500267 0H170.387V54.6013H33.8222L54.4357 35.2544L47.994 141.448L38.1167 129.41C45.8468 123.104 54.6505 118.518 64.5278 115.652C74.4051 112.785 83.6382 111.352 92.2271 111.352C111.695 111.352 128.802 115.723 143.546 124.465C158.434 133.064 170.029 144.959 178.331 160.15C186.777 175.197 191 192.466 191 211.956C191 231.447 185.918 249.074 175.755 264.838C165.591 280.459 152.207 292.927 135.601 302.242C118.996 311.414 100.959 316 81.491 316Z"
          fill="#9E9E9E"
          fillOpacity="0.16"
        />
      </svg>
    </>
  );
};

export default SIcon5;
