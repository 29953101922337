import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import ModalVideo from "react-modal-video";
import WorkWithShakib from "../Common/WorkWithShakib";
const Hero = () => {
  const [isOpen, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="hero-main-area-wrap">
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="L61p2uyiMSo"
        onClose={() => setOpen(false)}
      />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        className="modal-main-area-wrap"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WorkWithShakib handleClose={handleClose} />
        </Modal.Body>
      </Modal>
      <Container>
        <Row>
          {/* <Col lg="6">
            <div className="hero-image-left-area">
              <img src={heroLeft} alt="" />
            </div>
          </Col> */}
          <Col lg="12">
            <div className="hero-content-right-area">
              <h1>
                Marketing Strategist | Commercial Strategist | ED-Tech <br />{" "}
                Enthusiast | Social Entrepreneur
              </h1>
              <p>
                <span>
                  <b>A certified marketing professional</b>, Nazmus Shakib
                  brings over{" "}
                  <b>
                    14 years of leadership experience, driving success with
                    global, multi-cultural teams.
                  </b>{" "}
                  With a solid background in{" "}
                  <b>
                    marketing and consulting, he has successfully led key
                    functions for Fortune 500 companies and regional agencies.
                  </b>{" "}
                  Renowned for{" "}
                  <b>his ability to devise groundbreaking strategies,</b> Nazmus
                  consistently delivers outstanding results for clients while
                  offering expert guidance through strategic consulting.
                  Furthermore,{" "}
                  <b>he excels at identifying growth opportunities</b> in
                  challenging environments and prioritizes the design of
                  exceptional customer service journeys. With an innate knack
                  for{" "}
                  <b>
                    managing client expectations, leading by example, and
                    generating formidable return on investments,
                  </b>{" "}
                  he offers unparalleled expertise and value to any organization
                  seeking a marketing/consulting professional who consistently
                  goes above and beyond.
                </span>

                <span>
                  Worked with more than{" "}
                  <b>
                    7 industries and with 30+ clients, and managed more than 30+
                    teammates directly.
                  </b>{" "}
                  Currently, he is{" "}
                  <b>
                    managing Market Access Group to build future proof
                    platforms,
                  </b>{" "}
                  and rendering consulting services to start-ups, local
                  conglomerates in the area of{" "}
                  <b>
                    RTM, Strategic Brand management and Human Capital
                    development, to name a few.
                  </b>{" "}
                  All in all, he is{" "}
                  <b>
                    detailed-oriented, composed and a solid brand professional
                  </b>{" "}
                  who believes in disruptions, especially in the brand equity
                  front.
                </span>
              </p>
              <div className="hero-button-bottom-wrap">
                {/* <button
                  className="maac-portfolio-btn hero-button-two"
                  onClick={() => setOpen(true)}
                >
                  <VideoIcon /> Watch Video
                </button>{" "} */}
                <button className="maac-portfolio-btn " onClick={handleShow}>
                  Work with Nazmus
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
