import React from "react";

const SalesX = () => {
  return (
    <>
      <svg
        width="188"
        height="94"
        viewBox="0 0 188 94"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.963 4.50579C174.671 14.8561 163.403 28.2541 151.745 42.818C149.811 45.2337 148.258 47.3045 148.293 47.4195C148.382 47.7109 153.141 56.1976 156.863 62.7058C158.563 65.6776 159.891 68.1713 159.815 68.2472C159.738 68.3232 156.868 68.4484 153.436 68.5223C148.81 68.6228 147.137 68.5962 146.963 68.4196C146.835 68.2883 145.354 65.965 143.672 63.2558C141.991 60.5466 140.504 58.1658 140.365 57.9627C140.142 57.6343 139.693 58.0427 136.192 61.7596C134.034 64.0501 130.798 67.5392 129 69.5156C127.203 71.49 125.443 73.4234 125.089 73.8092L124.446 74.5112L125.067 74.0884C125.82 73.5753 133.629 66.9953 137.044 63.9967C138.99 62.2891 139.546 61.8971 139.691 62.1352C139.792 62.3014 140.986 64.555 142.345 67.1451C143.703 69.7352 144.884 71.919 144.971 71.999C145.12 72.1386 167.146 72.6702 167.281 72.5388C167.355 72.4649 160.952 60.8196 156.555 53.0307L153.563 47.7294L160.721 38.2534C170.801 24.9067 177.988 14.9792 184.476 5.43143C186.451 2.52523 188.025 0.146486 187.975 0.146486C187.922 0.144434 186.118 2.10859 183.963 4.50579Z"
          fill="#DE1F26"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.446 23.0836C153.728 24.5244 150.441 27.3177 148.144 29.2921C145.846 31.2666 143.889 32.9064 143.798 32.9393C143.707 32.9721 142.526 30.8725 141.174 28.2762C139.821 25.6779 138.576 23.3751 138.404 23.1555C138.109 22.7778 137.579 22.7491 127.033 22.5479C120.951 22.431 115.954 22.355 115.929 22.3796C115.865 22.4433 123.05 35.4699 126.609 41.7482L129.701 47.2014L123.236 55.7435C112.939 69.3509 104.742 80.6802 98.2006 90.3511C94.697 95.5314 94.6536 95.5663 102.182 87.0673C110.825 77.3102 118.401 68.296 128.663 55.5629C131.894 51.5566 134.655 48.1373 134.802 47.9649C135.087 47.6324 133.834 45.2886 126.471 32.4056C124.709 29.3209 123.321 26.7431 123.387 26.6774C123.557 26.5091 136.218 26.2217 136.285 26.3859C136.316 26.4598 137.86 28.9453 139.72 31.911L143.099 37.3027L147.109 33.0398C152.685 27.1145 158.782 20.4668 158.64 20.4668C158.603 20.4627 157.163 21.6428 155.446 23.0836Z"
          fill="#0180F5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.9531 41.9092V61.7149H55.3657H58.7782V41.9092V22.1035H55.3657H51.9531V41.9092Z"
          fill="#0B2E4E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.02271 32.7888C5.54086 33.2013 2.9887 34.638 1.66504 36.3641C0.570955 37.7905 0.146973 39.258 0.146973 41.6059C0.146973 43.3956 0.202814 43.6747 0.81914 44.9144C2.04765 47.3916 3.64637 48.4425 8.41979 49.9161C11.4042 50.8376 12.6327 51.4246 13.3566 52.2784C13.9709 53.0008 14.035 53.6084 13.6069 54.6263C13.1022 55.827 12.3494 56.0692 9.14366 56.0692C6.0248 56.0692 4.65567 55.7428 2.16348 54.4088L0.870845 53.7171V56.8039V59.8908L2.24826 60.5106C4.41574 61.4855 6.49429 61.9452 9.24707 62.0601C13.4042 62.2325 16.3183 61.3274 18.523 59.1827C20.3864 57.3683 21.0834 55.1928 20.7194 52.3215C20.4526 50.2219 19.8198 48.7503 18.6161 47.4429C17.069 45.762 15.8012 45.1196 11.7475 43.958C8.19021 42.9379 7.07754 42.2196 7.07754 40.943C7.07754 40.0625 7.39811 39.6048 8.37637 39.0856C9.16229 38.6689 9.53664 38.6238 12.1446 38.6238C14.9553 38.6238 16.0825 38.8146 18.3431 39.6766C18.6181 39.7813 18.6595 39.4406 18.6595 37.1255V34.4553L17.16 33.8376C14.3928 32.7005 10.9409 32.3044 8.02271 32.7888Z"
          fill="#0B2E4E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.0933 41.3266C46.2205 37.0329 43.5401 34.217 39.2341 33.0739C37.7864 32.688 32.833 32.5094 30.9613 32.7742C29.4949 32.9815 27.2634 33.4823 26.463 33.784L25.8942 33.9995V39.5984L26.3595 39.465C29.1951 38.6461 30.165 38.4799 32.74 38.3773C34.6303 38.3013 35.9043 38.3465 36.6158 38.5168C38.7212 39.0176 40.0552 40.5159 40.3117 42.6729L40.4358 43.7115L39.5258 43.5904C34.4752 42.9172 29.3729 43.5801 26.7215 45.2569C22.0556 48.2062 21.5199 54.9525 25.6295 59.0224C26.9138 60.2928 28.3037 61.0501 30.4443 61.6412C32.3284 62.1605 38.0346 62.1749 39.9146 61.6638C43.112 60.7956 45.1451 59.1886 46.506 56.4569L47.3001 54.8642L47.3663 48.9307C47.4201 44.0522 47.3725 42.6996 47.0933 41.3266ZM40.5247 50.6568C40.3799 53.7415 40.0014 54.5563 38.3034 55.4204C37.228 55.9684 37.046 55.9951 34.9943 55.9232C32.407 55.8329 31.3832 55.4143 30.496 54.0822C30.0058 53.3495 29.9272 53.0212 29.9272 51.7425C29.9272 50.5562 30.0141 50.1375 30.3595 49.6757C30.8951 48.9553 32.2043 48.2144 33.379 47.964C34.3635 47.7567 40.0201 47.8532 40.3985 48.0851C40.5661 48.1877 40.6033 48.941 40.5247 50.6568Z"
          fill="#0B2E4E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.1644 54.2213C83.5037 55.6641 80.2959 56.2757 77.1109 56.1341C73.7728 55.9864 71.7956 54.9088 71.0055 52.8031C70.7925 52.2305 70.5505 51.3705 70.4699 50.8902L70.323 50.018H89.2285L89.1313 46.4776C89.0238 42.5246 88.7446 41.2912 87.3589 38.6271C86.4447 36.8703 84.3372 34.6947 82.8005 33.921C80.085 32.5561 75.4894 32.1498 72.263 32.9871C66.8319 34.3992 64.044 38.5779 63.4297 46.221C63.2808 48.0702 63.5724 51.35 64.0502 53.1992C64.5486 55.1387 65.864 57.5913 66.987 58.6771C69.2869 60.9019 71.8204 61.8952 75.6342 62.0676C79.5017 62.2441 83.4148 61.5484 86.3702 60.1609L87.5264 59.6171V56.8484C87.5264 54.2931 87.4974 54.0899 87.1644 54.2213ZM70.5195 44.2712C70.6519 42.1224 71.6446 40.0536 72.9765 39.1484C74.5256 38.0935 76.8234 37.915 78.4035 38.7236C79.8761 39.4748 81.0777 41.1844 81.6609 43.3477C81.814 43.9121 81.9381 44.5832 81.9402 44.8398L81.9422 45.3036L76.203 45.2502L70.4637 45.1948L70.5195 44.2712Z"
          fill="#0B2E4E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.8324 32.9129C97.9235 33.3173 96.0042 34.2716 94.7736 35.4312C91.5265 38.4914 91.5038 44.3407 94.726 47.1607C96.0249 48.2957 96.7053 48.6179 100.312 49.7939C103.921 50.972 104.862 51.4153 105.597 52.2815C106.209 53.0039 106.273 53.6114 105.847 54.6273C105.392 55.7069 104.542 56.0394 101.981 56.1338C99.2823 56.2344 97.09 55.7562 94.7136 54.5452L93.1108 53.7284V56.8111V59.8938L94.4882 60.5136C96.6184 61.4721 98.728 61.938 101.487 62.0591C105.628 62.2397 108.66 61.2977 110.804 59.1632C112.69 57.2873 113.35 55.1363 112.953 52.1706C112.674 50.0813 112.018 48.6569 110.705 47.2777C109.429 45.9375 108.31 45.3218 105.623 44.4762C100.159 42.7542 99.3154 42.2863 99.3154 40.9871C99.3154 40.0635 99.6235 39.612 100.614 39.0866C101.4 38.6699 101.774 38.6248 104.382 38.6248C107.193 38.6248 108.318 38.8156 110.581 39.6777C110.856 39.7823 110.897 39.4416 110.897 37.1265V34.4563L109.398 33.8386C106.413 32.6112 102.883 32.2685 99.8324 32.9129Z"
          fill="#0B2E4E"
        />
      </svg>
    </>
  );
};

export default SalesX;
