import React from "react";

const DataAnalytics = () => {
  return (
    <>
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.75 64.1667H61.25"
          stroke="#EB2027"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3333 24.4417H11.6667C10.0625 24.4417 8.75 25.7542 8.75 27.3583V52.5C8.75 54.1042 10.0625 55.4167 11.6667 55.4167H16.3333C17.9374 55.4167 19.2499 54.1042 19.2499 52.5V27.3583C19.2499 25.7542 17.9374 24.4417 16.3333 24.4417Z"
          stroke="#EB2027"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.3313 15.1375H32.6647C31.0605 15.1375 29.748 16.45 29.748 18.0542V52.5C29.748 54.1042 31.0605 55.4167 32.6647 55.4167H37.3313C38.9355 55.4167 40.248 54.1042 40.248 52.5V18.0542C40.248 16.45 38.9355 15.1375 37.3313 15.1375Z"
          stroke="#EB2027"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.3333 5.83334H53.6667C52.0625 5.83334 50.75 7.14584 50.75 8.75V52.5C50.75 54.1042 52.0625 55.4167 53.6667 55.4167H58.3333C59.9374 55.4167 61.2499 54.1042 61.2499 52.5V8.75C61.2499 7.14584 59.9374 5.83334 58.3333 5.83334Z"
          stroke="#EB2027"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default DataAnalytics;
