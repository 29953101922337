import React from "react";

const FieldX = () => {
  return (
    <div>
      <svg
        width="182"
        height="85"
        viewBox="0 0 182 85"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_161_3309)">
          <path
            d="M128.955 48.8213L133.16 43.5578L133.975 42.5371L133.324 41.3747L130.487 36.3002C129.544 34.6075 128.582 32.922 127.628 31.2365C126.674 29.5511 125.716 27.8656 124.744 26.1874C124.309 25.4355 123.877 24.6835 123.442 23.928L124.078 23.9171L127.994 23.8408L131.909 23.75L134.962 23.6664L137.324 27.4914L140.223 32.112L141.053 33.4378L142.058 32.3735C143.162 31.2038 144.277 30.0414 145.378 28.8645C146.482 27.6912 147.578 26.5143 148.672 25.3301L151.933 21.763C152.477 21.1709 153.011 20.5643 153.549 19.965C154.086 19.3656 154.623 18.7626 155.15 18.1523C154.521 18.6573 153.896 19.1731 153.274 19.6852C152.653 20.1974 152.028 20.7096 151.413 21.2327L147.721 24.3566C146.496 25.4028 145.275 26.4562 144.061 27.5132C143.235 28.2288 142.413 28.9481 141.594 29.6709L139.747 26.122L137.196 21.3053L136.79 20.5425L135.828 20.5171L131.913 20.4081L127.997 20.3173L124.082 20.241L120.166 20.1793L116.946 20.1284L118.496 23.0053C119.417 24.7126 120.364 26.4017 121.296 28.1017C122.228 29.8017 123.175 31.4944 124.122 33.1835C125.069 34.8763 126.009 36.5689 126.97 38.2508L129.2 42.1775L125.906 46.4674L121.837 51.8362C119.143 55.4288 116.456 59.0285 113.812 62.661C111.162 66.2862 108.541 69.9369 105.956 73.6166C104.662 75.4546 103.378 77.2999 102.113 79.1597C100.841 81.0123 99.5836 82.8794 98.3516 84.7646C99.8907 83.1155 101.401 81.4446 102.896 79.7627C104.402 78.0881 105.886 76.399 107.363 74.7027C110.317 71.3099 113.235 67.8918 116.123 64.4446C119.015 61.0046 121.87 57.5356 124.711 54.0557L128.955 48.8213Z"
            fill="#0052CC"
          />
          <path
            d="M176.591 5.00636C175.084 6.68094 173.6 8.37004 172.123 10.0664C169.169 13.4591 166.252 16.8773 163.364 20.3245C160.472 23.7645 157.616 27.2335 154.776 30.7134L150.535 35.9514L146.331 41.2151L145.515 42.2358L146.166 43.3982L149.003 48.4728C149.946 50.1655 150.908 51.851 151.862 53.5364C152.816 55.2219 153.774 56.9074 154.747 58.5856C155.182 59.3375 155.613 60.0894 156.048 60.845L155.412 60.8559L151.496 60.9322L147.581 61.023L144.528 61.1065L142.167 57.2815L139.267 52.661L138.438 51.3352L137.432 52.3995C136.328 53.5691 135.213 54.7315 134.113 55.9085C133.009 57.0817 131.912 58.2587 130.819 59.4429L127.558 63.01C127.013 63.602 126.479 64.2087 125.942 64.808C125.404 65.4074 124.867 66.0104 124.34 66.6206C124.969 66.1157 125.594 65.5999 126.216 65.0877C126.837 64.5755 127.462 64.0634 128.077 63.5403L131.769 60.4164C132.994 59.3702 134.215 58.3168 135.429 57.2597C136.255 56.5441 137.078 55.8249 137.896 55.102L139.743 58.651L142.295 63.4676L142.7 64.2305L143.662 64.2559L147.577 64.3649L151.493 64.4557L155.408 64.532L159.324 64.5937L162.545 64.6446L160.995 61.7676C160.073 60.0604 159.126 58.3713 158.194 56.6713C157.262 54.9713 156.315 53.2785 155.368 51.5894C154.421 49.8967 153.482 48.204 152.52 46.5221L150.29 42.5991L153.584 38.3091L157.653 32.9401C160.347 29.3476 163.035 25.7478 165.678 22.1153C168.328 18.4901 170.95 14.8395 173.534 11.1598C174.829 9.32175 176.112 7.47645 177.377 5.61662C178.649 3.76406 179.907 1.89697 181.139 0.0117188C179.596 1.6536 178.086 3.32454 176.591 5.00636Z"
            fill="#0052CC"
          />
          <path
            d="M0 57.6672V23.5039H23.8658V30.5727H9.32989V37.8956H21.8551V45.0153H9.32989V57.6672H0Z"
            fill="#0B2E4E"
          />
          <path
            d="M28.1465 28.369V22.0703H36.9097V28.369H28.1465ZM28.2525 57.6685V32.1105H36.811L36.9134 57.6685H28.2525Z"
            fill="#0B2E4E"
          />
          <path
            d="M54.1782 58.433C51.4984 58.433 49.133 57.8954 47.0894 56.8202C45.0457 55.745 43.4554 54.2085 42.3221 52.2106C41.1887 50.2127 40.6221 47.848 40.6221 45.1164C40.6221 40.5758 41.7737 37.0414 44.0769 34.5133C46.3801 31.9851 49.696 30.7246 54.0246 30.7246C56.9128 30.7246 59.2489 31.3385 61.0367 32.5699C62.8244 33.7977 64.1479 35.6248 65.007 38.0512C65.8661 40.4777 66.2793 43.4454 66.2427 46.9617H46.552L45.521 41.789H59.0259L57.995 44.5533C57.9621 41.789 57.6074 39.8493 56.9384 38.7414C56.2694 37.6335 55.1616 37.0777 53.6152 37.0777C52.7889 37.0777 52.0358 37.3065 51.3485 37.7679C50.6612 38.2292 50.1201 38.9702 49.7253 39.9946C49.3305 41.0189 49.133 42.4029 49.133 44.1429C49.133 46.5003 49.6924 48.3021 50.8074 49.548C51.9225 50.7939 53.7724 51.4187 56.3498 51.4187C57.2784 51.4187 58.207 51.3097 59.132 51.0845C60.0606 50.863 60.927 50.5978 61.735 50.289C62.5429 49.9803 63.2046 49.7442 63.7201 49.5734V56.7439C62.5173 57.1871 61.15 57.5794 59.6219 57.9209C58.0864 58.2623 56.273 58.433 54.1782 58.433Z"
            fill="#0B2E4E"
          />
          <path
            d="M78.4027 57.6648H69.9502V21.9141H78.4027V57.6648Z"
            fill="#0B2E4E"
          />
          <path
            d="M96.4954 58.436C94.2616 58.436 92.2582 58.2072 90.4887 57.7458C88.7193 57.2845 87.2167 56.5253 85.9773 55.4647C84.7416 54.4076 83.7874 52.98 83.1148 51.1892C82.4457 49.3984 82.1094 47.1535 82.1094 44.4546C82.1094 40.2228 83.1586 36.8736 85.2535 34.4144C87.3483 31.9552 90.5107 30.7274 94.7369 30.7274C95.2195 30.7274 95.8885 30.771 96.7477 30.8546C97.6068 30.9417 98.5171 31.1524 99.4786 31.4939C100.44 31.8353 101.318 32.4492 102.107 33.3392L100.51 36.0018V21.0469H108.911V50.4446C108.911 51.7414 108.575 52.8856 107.906 53.8773C107.236 54.8689 106.315 55.7044 105.149 56.3873C103.979 57.0702 102.648 57.5824 101.153 57.9238C99.6578 58.2653 98.1077 58.436 96.4954 58.436ZM96.8025 51.4181C97.9358 51.4181 98.8388 51.2655 99.5079 50.9567C100.177 50.648 100.513 50.1721 100.513 49.5219V38.4574C99.9649 38.1486 99.3945 37.9198 98.8132 37.7672C98.2283 37.6146 97.6434 37.5384 97.0621 37.5384C95.6874 37.5384 94.5175 37.7418 93.556 38.1523C92.5945 38.5627 91.8707 39.2892 91.3917 40.3281C90.9092 41.3706 90.6715 42.8454 90.6715 44.7597C90.6715 46.1946 90.8763 47.4078 91.2894 48.3959C91.7025 49.3875 92.3642 50.1358 93.2745 50.648C94.1812 51.1638 95.3584 51.4181 96.8025 51.4181Z"
            fill="#0B2E4E"
          />
        </g>
        <defs>
          <clipPath id="clip0_161_3309">
            <rect width="181.333" height="85" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default FieldX;
