import React from "react";

const SIcon1 = () => {
  return (
    <>
      <svg
        width="121"
        height="316"
        viewBox="0 0 121 316"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M59.25 316V64.9556L0 101.383V35.55L59.25 0H120.256V316H59.25Z"
          fill="#9E9E9E"
          fillOpacity="0.16"
        />
      </svg>
    </>
  );
};

export default SIcon1;
