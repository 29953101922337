import logo1 from "../assets/images/majorBrand/logo-1.svg";
import logo10 from "../assets/images/majorBrand/logo-10.svg";
import logo13 from "../assets/images/majorBrand/logo-13.svg";
import logo15 from "../assets/images/majorBrand/logo-15.svg";
import logo17 from "../assets/images/majorBrand/logo-17.svg";
import logo18 from "../assets/images/majorBrand/logo-18.svg";
import logo19 from "../assets/images/majorBrand/logo-19.svg";
import logo2 from "../assets/images/majorBrand/logo-2.svg";
import logo23 from "../assets/images/majorBrand/logo-23.svg";
import logo28 from "../assets/images/majorBrand/logo-28.svg";
import logo33 from "../assets/images/majorBrand/logo-33.svg";
import logo36 from "../assets/images/majorBrand/logo-36.svg";
import logo6 from "../assets/images/majorBrand/logo-6.svg";
import logo7 from "../assets/images/majorBrand/logo-7.svg";
import logo9 from "../assets/images/majorBrand/logo-9.svg";
export const execution = [
  {
    logo: logo6,
  },
  {
    logo: logo7,
  },

  {
    logo: logo9,
  },
];
export const leadership = [
  {
    logo: logo2,
  },
  {
    logo: logo10,
  },

  {
    logo: logo17,
  },
  {
    logo: logo19,
  },
];
export const cxoLevel = [
  {
    logo: logo13,
  },

  {
    logo: logo15,
  },

  {
    logo: logo23,
  },

  {
    logo: logo28,
  },

  {
    logo: logo33,
  },

  {
    logo: logo36,
  },
];

export const experianceData = [
  {
    logo: logo13,
    title: "Chief Marketing Officer (Remote)  ",
    company: "Market Access Group, <br /> Dhaka, Bangladesh   ",
    year: " Sep 2020 – Present",
    Role: "Manage Startups",

    Budget: "USD 250,000 ",
    people: 15,
    details: [
      {
        list: "Created 6 platforms for retail and ed-tech fronts",
      },
      {
        list: "Delivered valuation for MAAC at $15mln",
      },
      {
        list: "Served 5+ MNC clients and generated 10K B2C clients in two Quarters",
      },
    ],
  },
  {
    logo: logo1,
    title: "Chief Marketing Officer",
    company: "BAY Developments Limited, <br /> Dhaka, Bangladesh",
    year: " Sep 2019 – Aug 2020",

    Role: "Manage Managers",
    Budget: "USD 150,000 ",
    people: 6,
    details: [
      {
        list: "Launched 3 mega projects worth of $650 mln",
      },
      {
        list: "Generated staggering 27% growth even in Covid momentum",
      },
      {
        list: "Completed Business Process re-engineering with a 23% NPAT growth in dividends",
      },
    ],
  },

  {
    logo: logo2,
    title: "Head of Marketing and Communications",
    company: "RANCON Holdings Limited, <br /> Bangladesh",
    year: " Oct 2017 – Sep 2019",

    Role: "Manage Managers",
    Budget: "USD 100,000 ",
    people: 11,
    details: [
      {
        list: "Launched 3 mega campaigns for SUZUKI Bangladesh allowing production facilities to ND",
      },
      {
        list: "Generated staggering 43% growth in two-wheeler segment, and 62% in Real estate segment",
      },
      {
        list: "Recalibrated RANCON as a corporate identity in the wider world ",
      },
    ],
  },

  {
    logo: logo18,
    title: "Head of Marketing and communications",
    company: "Siam City Cement Bangladesh Limited,<br/> Bangladesh & Thailand",
    year: "  May 2015 – Sep 2017",

    Role: "Manage Others",
    Budget: "USD 100,000 ",
    people: 11,
    details: [
      {
        list: "Launched INSEE cement in Bangladesh ",
      },
      {
        list: "Managed 4300 retails and 53 distributors ",
      },
      {
        list: "Generated 23% TOM in one year and 34% Value ",
      },
    ],
  },

  {
    logo: logo6,
    title: "Business Development Manager",
    company: "NOKIA Emerging Asia",
    year: "Mar 2011 – Sep 2011",

    Role: "Manage Managers",
    Budget: "USD 50,000 ",
    people: 2,
    details: [
      {
        list: "Co-created trade loyalty brand with 45,000 retails ",
      },
      {
        list: "Managed 10+ international brands, and 15+ events and activations",
      },
      {
        list: "Led the in-house training vertical for NOKIA Emerging Asia ",
      },
      {
        list: "Delivered 45% year-on-year growth in SBU",
      },
    ],
  },

  {
    logo: logo9,
    title: "Territory Officer",
    company: "British American Tobacco Bangladesh",
    year: " Sep 2007 – Feb 2011",

    Role: "Manage Self",
    Budget: "USD 10,000 ",
    people: 11,
    details: [
      {
        list: "Delivered 23% CAGR growth in red markets for Tobacco segment",
      },
      {
        list: "Managed 5 national distributors in 3 different locations",
      },
      {
        list: "Scaled-up national distribution reach by 37% using DX methodology",
      },
    ],
  },
];
