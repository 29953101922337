/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../Common/Button";
import WorkWithShakib from "../../Common/WorkWithShakib";
import BlackBeltTM from "./icon/BlackBeltTM";
import DataAnalytics from "./icon/DataAnalytics";
import EDTech from "./icon/EDTech";
import MarketingSales from "./icon/MarketingSales";
import StrategyInsights from "./icon/StrategyInsights";
import TradeMarketing from "./icon/TradeMarketing";

import "./your-need.scss";

const yourNeedData = [
  {
    icon: <TradeMarketing />,
    title: "TM&D Strategy",
    content:
      "Trade marketing solutions <br/> RTM strategy and applications <br /> Loyalty mapping",
  },

  {
    icon: <MarketingSales />,
    title: "Marketing Insights",
    content:
      "Brand positioning<br/> U&A audit and Brand perception indexing<br/> Brand launch and GTM",
  },
  {
    icon: <StrategyInsights />,
    title: "Sales Management",
    content:
      " The art of Sales<br/> Advanced B2B Sales process<br/> Sales Management Institute<br/> Certifications",
  },

  {
    icon: <DataAnalytics />,
    title: "Data Analytics & decision Matrix",
    content:
      "Data hub for Retails, consumers and activations<br/> Start-up support cell<br/> Data driven marketing<br/> Digital Mileage",
  },
  {
    icon: <BlackBeltTM />,
    title: "Road to transformation",
    content:
      "Professional Excellence training<br/> Management consulting<br/>  Operational Road map <br /> Black Belt <sup> TM</sup> <br /> E-square<sup> TM</sup> ",
  },

  {
    icon: <EDTech />,
    title: "Tools and techniques",
    content: "Apps and web-based solutions <br/> ED tech platforms",
  },
];

const YourNeed = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        className="modal-main-area-wrap"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WorkWithShakib handleClose={handleClose} />
        </Modal.Body>
      </Modal>
      <div className="your-need-main-wrap" id="Expertise">
        <div className="container">
          <div className="your-need-inner-wrap">
            <div className="your-need-title-wrap ">
              <div className="common-title">
                <h2>Expertise</h2>
                <p>Areas where companies and pros trust</p>
              </div>
            </div>
            <div className="your-need-item-wrap">
              <div className="row">
                {yourNeedData.map((data, i) => {
                  return (
                    <div className="col-md-4" key={i}>
                      <div
                        className="your-need-single-item"
                        onClick={handleShow}
                      >
                        <div className="your-need-single-item-data">
                          {data.icon}
                          <h3
                            dangerouslySetInnerHTML={{ __html: data.title }}
                          ></h3>
                          <p
                            dangerouslySetInnerHTML={{ __html: data.content }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="hero-button-bottom-wrap">
                <a onClick={handleShow}>
                  <Button>Work With Nazmus</Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourNeed;
