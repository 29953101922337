import React, { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import heroLeft from "../assets/images/hero/hero-left.png";
import WorkWithShakib from "../Common/WorkWithShakib";
import { shakibData } from "../FackData/shakibData";
const HeroTwo = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <section className="hero-main-bg-video-area-shape">
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        className="modal-main-area-wrap"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WorkWithShakib handleClose={handleClose} />
        </Modal.Body>
      </Modal>

      {/* <VideoPlayer
        className="video"
        src={
          "https://sgp1.digitaloceanspaces.com/staging-salesx/1644319489969-high-rise-loop-h264-2040.mp4"
        }
        autoPlay={true}
        muted={true}
      /> */}
      <>
        <Swiper
          modules={[Autoplay, Navigation, Pagination]}
          slidesPerView={1}
          autoplay={true}
          loop={true}
          Navigation={true}
          pagination={true}
          speed={1400}
        >
          {shakibData.map((item, idx) => (
            <SwiperSlide key={idx}>
              <div
                className="hero-inner-area-main-wrap"
                style={{
                  backgroundImage: `url(${item.background})`,
                }}
              >
                <div className="ovarlay-content"></div>
                <Container style={{ position: "relative" }}>
                  <div className="hero-content hero-top-main-content">
                    <div className="hero-top-main-content-data-wrap">
                      <h2>{item.title}</h2>
                      {/* <br /> */}
                      <p>{item.content}</p>
                    </div>
                    <div className="hero-button-bottom-wrap">
                      <button
                        className="maac-portfolio-btn hero-button-two"
                        onClick={handleShow}
                      >
                        Work with Nazmus
                      </button>
                    </div>
                  </div>
                </Container>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="right-profile-image-area">
          <img src={heroLeft} alt="" />
        </div>
      </>
    </section>
  );
};

export default HeroTwo;
