import React from "react";

const MarketingSales = () => {
  return (
    <>
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.83398 5.83334V55.4167C5.83398 60.2583 9.74232 64.1667 14.584 64.1667H64.1673"
          stroke="#EB2027"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.584 49.5833L27.9715 33.95C30.1882 31.3834 34.1257 31.2083 36.5173 33.6292L39.2882 36.4C41.6798 38.7917 45.6173 38.6458 47.834 36.0791L61.2507 20.4167"
          stroke="#EB2027"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default MarketingSales;
