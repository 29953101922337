import React from "react";

const TradeMarketing = () => {
  return (
    <>
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.0684 52.9375V46.9"
          stroke="#EB2027"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M35 52.9375V40.8625"
          stroke="#EB2027"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M49.9316 52.9375V34.7958"
          stroke="#EB2027"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M49.935 17.0624L48.5934 18.6374C41.1559 27.3291 31.1809 33.4833 20.0684 36.2541"
          stroke="#EB2027"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M41.3867 17.0624H49.9326V25.5791"
          stroke="#EB2027"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.2507 64.1667H43.7507C58.334 64.1667 64.1673 58.3333 64.1673 43.75V26.25C64.1673 11.6666 58.334 5.83331 43.7507 5.83331H26.2507C11.6673 5.83331 5.83398 11.6666 5.83398 26.25V43.75C5.83398 58.3333 11.6673 64.1667 26.2507 64.1667Z"
          stroke="#EB2027"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default TradeMarketing;
