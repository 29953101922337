import React from "react";

const RoleIconEx = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 15.716H14.3667C13.7 15.716 13.0667 15.9743 12.6 16.441L11.175 17.8493C10.525 18.491 9.46668 18.491 8.81668 17.8493L7.39166 16.441C6.925 15.9743 6.28333 15.716 5.625 15.716H5C3.61667 15.716 2.5 14.6077 2.5 13.241V4.14102C2.5 2.77435 3.61667 1.66602 5 1.66602H15C16.3833 1.66602 17.5 2.77435 17.5 4.14102V13.2327C17.5 14.5993 16.3833 15.716 15 15.716Z"
        stroke="#292D32"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0589 7.45898C10.0255 7.45898 9.97553 7.45898 9.93386 7.45898C9.05886 7.42565 8.36719 6.71732 8.36719 5.83398C8.36719 4.93398 9.0922 4.20898 9.9922 4.20898C10.8922 4.20898 11.6172 4.94232 11.6172 5.83398C11.6255 6.71732 10.9339 7.43398 10.0589 7.45898Z"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.7082 9.9668C6.59987 10.7085 6.59987 11.9168 7.7082 12.6585C8.96654 13.5001 11.0332 13.5001 12.2915 12.6585C13.3999 11.9168 13.3999 10.7085 12.2915 9.9668C11.0332 9.13346 8.97487 9.13346 7.7082 9.9668Z"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RoleIconEx;
