import React from "react";

const IconFour = () => {
  return (
    <>
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25 42.375H15.525C7.1 42.375 5 40.275 5 31.85V16.85C5 8.42501 7.1 6.32501 15.525 6.32501H41.85C50.275 6.32501 52.375 8.42501 52.375 16.85"
          stroke="#0B2E4E"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25 53.675V42.375"
          stroke="#0B2E4E"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 32.375H25"
          stroke="#0B2E4E"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.8501 53.675H25.0001"
          stroke="#0B2E4E"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.0002 32V46.275C55.0002 52.2 53.5252 53.675 47.6002 53.675H38.7252C32.8002 53.675 31.3252 52.2 31.3252 46.275V32C31.3252 26.075 32.8002 24.6 38.7252 24.6H47.6002C53.5252 24.6 55.0002 26.075 55.0002 32Z"
          stroke="#0B2E4E"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.1113 45.625H43.1337"
          stroke="#0B2E4E"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default IconFour;
