import React from "react";

const IconFive = () => {
  return (
    <>
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M55 37.5V22.5C55 10 50 5 37.5 5H22.5C10 5 5 10 5 22.5V37.5C5 50 10 55 22.5 55H37.5C50 55 55 50 55 37.5Z"
          stroke="#292D32"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.2998 17.775H53.6998"
          stroke="#292D32"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.2998 5.27502V17.425"
          stroke="#292D32"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.7002 5.27502V16.3"
          stroke="#292D32"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.375 36.125V33.125C24.375 29.275 27.1 27.7 30.425 29.625L33.025 31.125L35.625 32.625C38.95 34.55 38.95 37.7 35.625 39.625L33.025 41.125L30.425 42.625C27.1 44.55 24.375 42.975 24.375 39.125V36.125V36.125Z"
          stroke="#292D32"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default IconFive;
