import React from "react";

const BudgetIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0841 6.60023V10.8919C16.0841 13.4586 14.6175 14.5586 12.4175 14.5586H5.09244C4.71744 14.5586 4.35911 14.5253 4.02578 14.4503C3.81744 14.4169 3.61745 14.3586 3.43412 14.2919C2.18412 13.8253 1.42578 12.7419 1.42578 10.8919V6.60023C1.42578 4.03356 2.89244 2.93359 5.09244 2.93359H12.4175C14.2841 2.93359 15.6258 3.72526 15.9841 5.53359C16.0424 5.86692 16.0841 6.20856 16.0841 6.60023Z"
        stroke="#292D32"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5857 9.09984V13.3915C18.5857 15.9582 17.119 17.0582 14.919 17.0582H7.59401C6.97734 17.0582 6.41902 16.9749 5.93568 16.7915C4.94402 16.4249 4.26901 15.6665 4.02734 14.4499C4.36068 14.5249 4.71901 14.5582 5.09401 14.5582H12.419C14.619 14.5582 16.0857 13.4582 16.0857 10.8915V6.59984C16.0857 6.20818 16.0523 5.8582 15.9857 5.5332C17.569 5.86654 18.5857 6.98318 18.5857 9.09984Z"
        stroke="#292D32"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.74884 10.9508C9.96387 10.9508 10.9489 9.96582 10.9489 8.75079C10.9489 7.53577 9.96387 6.55078 8.74884 6.55078C7.53381 6.55078 6.54883 7.53577 6.54883 8.75079C6.54883 9.96582 7.53381 10.9508 8.74884 10.9508Z"
        stroke="#292D32"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.98438 6.91602V10.5827"
        stroke="#292D32"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5195 6.91797V10.5847"
        stroke="#292D32"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BudgetIcon;
