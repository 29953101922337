import React from "react";
import Footer from "../../Common/Footer";
import HeaderCaseStudies from "../../Common/HeaderCaseStudies";
import SuccessStoriesAll from "../../Components/SuccessStoriesAll";

const CaseStudies = () => {
  return (
    <div className="case-studies-main-page-wrap">
      <HeaderCaseStudies />
      <SuccessStoriesAll />
      <Footer />
    </div>
  );
};

export default CaseStudies;
