import React from "react";

const Xelerator = () => {
  return (
    <>
      <svg
        width="154"
        height="48"
        viewBox="0 0 154 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.1139 27.6425L19.465 24.6614L19.9209 24.0832L19.557 23.4249L17.9706 20.5508C17.4431 19.592 16.9054 18.6374 16.3718 17.6828C15.8382 16.7282 15.3026 15.7736 14.7588 14.8231C14.5155 14.3972 14.2742 13.9713 14.0309 13.5434L14.3867 13.5372L16.5763 13.494L18.7658 13.4426L20.4729 13.3953L21.7936 15.5617L23.4149 18.1786L23.879 18.9296L24.4412 18.3268C25.0586 17.6643 25.6821 17.0059 26.2975 16.3394C26.9149 15.6748 27.5283 15.0082 28.1396 14.3376L29.9632 12.3172C30.2678 11.9819 30.5663 11.6383 30.8668 11.2988C31.1674 10.9594 31.4679 10.6179 31.7623 10.2722C31.4107 10.5582 31.0611 10.8503 30.7135 11.1404C30.3659 11.4305 30.0163 11.7206 29.6729 12.0169L27.608 13.7862C26.9231 14.3787 26.2403 14.9753 25.5615 15.574C25.0995 15.9793 24.6395 16.3867 24.1815 16.7961L23.1491 14.7861L21.7221 12.058L21.4952 11.626L20.9575 11.6116L18.7679 11.5498L16.5783 11.4984L14.3887 11.4552L12.1991 11.4202L10.398 11.3914L11.2648 13.0208C11.78 13.9878 12.3095 14.9445 12.8309 15.9073C13.3522 16.8702 13.8817 17.8289 14.4112 18.7856C14.9407 19.7443 15.4661 20.703 16.0038 21.6556L17.2509 23.8796L15.4089 26.3093L13.1334 29.3501C11.6267 31.3848 10.124 33.4236 8.6459 35.481C7.16369 37.5342 5.69783 39.6019 4.25242 41.686C3.52869 42.727 2.8111 43.7721 2.10372 44.8255C1.39226 45.8748 0.688973 46.9322 0 48C0.860706 47.066 1.70506 46.1196 2.54123 45.167C3.38354 44.2186 4.21358 43.2619 5.03953 42.3011C6.69143 40.3796 8.32288 38.4436 9.93799 36.4912C11.5551 34.5428 13.1518 32.5781 14.7404 30.6071L17.1139 27.6425Z"
          fill="#573B81"
        />
        <path
          d="M43.7527 2.82886C42.9104 3.77731 42.0804 4.73398 41.2544 5.69476C39.6025 7.61633 37.9711 9.5523 36.356 11.5047C34.7388 13.4531 33.1421 15.4178 31.5536 17.3888L29.182 20.3555L26.831 23.3366L26.375 23.9147L26.7389 24.5731L28.3254 27.4472C28.8529 28.4059 29.3906 29.3605 29.9242 30.3151C30.4578 31.2698 30.9934 32.2244 31.5372 33.1749C31.7805 33.6007 32.0218 34.0266 32.265 34.4545L31.9093 34.4607L29.7197 34.5039L27.5301 34.5553L25.823 34.6027L24.5023 32.4363L22.8811 29.8193L22.417 29.0684L21.8548 29.6712C21.2374 30.3337 20.6138 30.992 19.9985 31.6586C19.381 32.3231 18.7677 32.9897 18.1564 33.6604L16.3328 35.6807C16.0282 36.0161 15.7297 36.3596 15.4292 36.6991C15.1286 37.0386 14.8281 37.3801 14.5337 37.7257C14.8853 37.4398 15.2349 37.1476 15.5825 36.8575C15.93 36.5674 16.2796 36.2774 16.6231 35.9811L18.688 34.2118C19.3729 33.6193 20.0557 33.0226 20.7345 32.4239C21.1965 32.0186 21.6565 31.6113 22.1144 31.2019L23.1469 33.2119L24.5739 35.94L24.8008 36.372L25.3385 36.3864L27.5281 36.4481L29.7177 36.4995L31.9073 36.5428L34.0969 36.5777L35.898 36.6065L35.0312 34.9771C34.516 34.0102 33.9865 33.0535 33.4651 32.0906C32.9438 31.1278 32.4143 30.1691 31.8848 29.2124C31.3553 28.2537 30.8299 27.2949 30.2922 26.3424L29.0451 24.1204L30.8871 21.6907L33.1626 18.6499C34.6693 16.6152 36.172 14.5764 37.6501 12.519C39.1323 10.4658 40.5982 8.39813 42.0436 6.31402C42.7673 5.273 43.4849 4.22787 44.1923 3.1745C44.9037 2.12525 45.607 1.06777 46.296 0C45.4332 0.929925 44.5889 1.87631 43.7527 2.82886Z"
          fill="#EF4D39"
        />
        <path
          d="M45.3883 36.1311C43.8263 36.1311 42.4391 35.8126 41.2265 35.1754C40.0344 34.5178 39.0993 33.5929 38.4211 32.4009C37.7634 31.1883 37.4346 29.7496 37.4346 28.0849C37.4346 25.372 38.1025 23.2757 39.4384 21.7959C40.7949 20.2956 42.7268 19.5454 45.2342 19.5454C46.9606 19.5454 48.3478 19.9154 49.396 20.6552C50.4647 21.3951 51.2457 22.4741 51.739 23.8922C52.2322 25.3103 52.4686 27.0367 52.448 29.0714H40.5791L40.0858 26.1427H48.6561L47.9779 27.5916C47.9573 25.9269 47.721 24.7554 47.2688 24.0772C46.8167 23.399 46.0768 23.0599 45.0492 23.0599C44.4737 23.0599 43.9496 23.2037 43.4769 23.4915C43.0248 23.7792 42.6651 24.2622 42.3979 24.9404C42.1513 25.5981 42.028 26.5126 42.028 27.6841C42.028 29.1639 42.3774 30.3045 43.0762 31.1061C43.7955 31.9076 44.9362 32.3084 46.4981 32.3084C47.0736 32.3084 47.6388 32.2467 48.1937 32.1234C48.7692 31.9796 49.3035 31.8151 49.7968 31.6302C50.29 31.4452 50.7113 31.2808 51.0607 31.1369V35.0829C50.3209 35.3912 49.4885 35.6379 48.5636 35.8228C47.6593 36.0284 46.6009 36.1311 45.3883 36.1311Z"
          fill="#EF4138"
        />
        <path
          d="M60.2021 35.6687H55.6395V14.1813H60.2021V35.6687Z"
          fill="#EF4138"
        />
        <path
          d="M71.3396 36.1311C69.7776 36.1311 68.3903 35.8126 67.1777 35.1754C65.9857 34.5178 65.0506 33.5929 64.3724 32.4009C63.7147 31.1883 63.3858 29.7496 63.3858 28.0849C63.3858 25.372 64.0538 23.2757 65.3897 21.7959C66.7461 20.2956 68.6781 19.5454 71.1854 19.5454C72.9118 19.5454 74.2991 19.9154 75.3473 20.6552C76.416 21.3951 77.197 22.4741 77.6902 23.8922C78.1835 25.3103 78.4198 27.0367 78.3993 29.0714H66.5303L66.0371 26.1427H74.6074L73.9292 27.5916C73.9086 25.9269 73.6723 24.7554 73.2201 24.0772C72.768 23.399 72.0281 23.0599 71.0005 23.0599C70.425 23.0599 69.9009 23.2037 69.4282 23.4915C68.9761 23.7792 68.6164 24.2622 68.3492 24.9404C68.1026 25.5981 67.9793 26.5126 67.9793 27.6841C67.9793 29.1639 68.3287 30.3045 69.0274 31.1061C69.7468 31.9076 70.8874 32.3084 72.4494 32.3084C73.0249 32.3084 73.5901 32.2467 74.145 32.1234C74.7204 31.9796 75.2548 31.8151 75.748 31.6302C76.2413 31.4452 76.6626 31.2808 77.012 31.1369V35.0829C76.2721 35.3912 75.4398 35.6379 74.5149 35.8228C73.6106 36.0284 72.5522 36.1311 71.3396 36.1311Z"
          fill="#EF4138"
        />
        <path
          d="M81.5908 35.6687V21.7034C81.8785 21.4568 82.3923 21.1793 83.1322 20.871C83.8721 20.5422 84.7147 20.2545 85.6601 20.0078C86.6261 19.7407 87.592 19.6071 88.558 19.6071C89.5239 19.6071 90.2844 19.679 90.8393 19.8229C91.3942 19.9667 91.8155 20.1517 92.1032 20.3778V23.7381C91.7539 23.6559 91.312 23.5942 90.7776 23.5531C90.2433 23.512 89.6884 23.4915 89.1129 23.4915C88.5374 23.4709 87.9928 23.4709 87.479 23.4915C86.9652 23.512 86.5336 23.5531 86.1842 23.6148V35.6687H81.5908Z"
          fill="#EF4138"
        />
        <path
          d="M100.789 36.1311C99.8433 36.1311 98.9492 36.0386 98.1066 35.8537C97.2845 35.6892 96.5549 35.4118 95.9178 35.0213C95.3012 34.6102 94.8182 34.0656 94.4688 33.3874C94.1195 32.6886 93.9448 31.8254 93.9448 30.7978C93.9448 29.8318 94.14 28.9892 94.5305 28.2699C94.9415 27.5505 95.5581 26.9956 96.3802 26.6051C97.2023 26.2146 98.2402 26.0194 99.4939 26.0194C100.193 26.0194 100.871 26.0913 101.529 26.2352C102.186 26.3791 102.772 26.5743 103.286 26.8209C103.82 27.0676 104.241 27.3347 104.55 27.6225L104.735 29.8421C104.324 29.5955 103.83 29.4002 103.255 29.2564C102.679 29.1125 102.063 29.0406 101.405 29.0406C100.398 29.0406 99.6583 29.1947 99.1856 29.503C98.7334 29.7907 98.5074 30.2532 98.5074 30.8903C98.5074 31.2808 98.6204 31.6302 98.8465 31.9384C99.0726 32.2467 99.3706 32.4831 99.7405 32.6475C100.131 32.8119 100.532 32.8941 100.943 32.8941C101.991 32.8941 102.721 32.8222 103.132 32.6783C103.563 32.5345 103.779 32.2467 103.779 31.8151V26.081C103.779 25.1356 103.471 24.4574 102.854 24.0464C102.258 23.6148 101.405 23.399 100.295 23.399C99.3911 23.399 98.4765 23.5634 97.5517 23.8922C96.6474 24.2005 95.9075 24.5807 95.332 25.0329V20.9635C95.8664 20.6347 96.6885 20.3264 97.7983 20.0387C98.9287 19.7509 100.193 19.6071 101.59 19.6071C102.083 19.6071 102.628 19.6482 103.224 19.7304C103.82 19.8126 104.416 19.9667 105.012 20.1928C105.608 20.3983 106.153 20.7066 106.646 21.1177C107.16 21.5287 107.571 22.0528 107.879 22.6899C108.187 23.327 108.342 24.1183 108.342 25.0637L108.311 32.4317C108.311 33.3155 108.013 34.0348 107.417 34.5897C106.821 35.1241 105.958 35.5145 104.827 35.7612C103.717 36.0078 102.371 36.1311 100.789 36.1311Z"
          fill="#EF4138"
        />
        <path
          d="M118.801 36.2853C116.992 36.2853 115.646 35.8639 114.762 35.0213C113.878 34.1581 113.437 32.9455 113.437 31.3835V23.5839H111.155V20.0695H113.437V15.7844H118.246V20.0695H121.668V23.5839H118.246V30.7978C118.246 31.3322 118.359 31.7329 118.585 32.0001C118.811 32.2467 119.191 32.37 119.726 32.37C120.034 32.37 120.373 32.3084 120.743 32.1851C121.113 32.0618 121.421 31.9076 121.668 31.7226V35.6687C121.236 35.8948 120.763 36.0489 120.25 36.1311C119.756 36.2339 119.273 36.2853 118.801 36.2853Z"
          fill="#EF4138"
        />
        <path
          d="M132.183 36.1311C129.552 36.1311 127.569 35.4323 126.233 34.0348C124.918 32.6167 124.26 30.5409 124.26 27.8074C124.26 24.8685 124.928 22.7619 126.264 21.4876C127.62 20.1928 129.593 19.5454 132.183 19.5454C133.93 19.5454 135.389 19.8434 136.56 20.4394C137.753 21.0149 138.647 21.9089 139.243 23.1215C139.859 24.3341 140.167 25.8961 140.167 27.8074C140.167 30.5409 139.479 32.6167 138.102 34.0348C136.725 35.4323 134.752 36.1311 132.183 36.1311ZM132.183 32.4625C132.943 32.4625 133.57 32.2878 134.063 31.9384C134.577 31.5685 134.957 31.0444 135.204 30.3662C135.471 29.6674 135.605 28.8145 135.605 27.8074C135.605 26.6565 135.471 25.7522 135.204 25.0945C134.957 24.4163 134.577 23.9333 134.063 23.6456C133.55 23.3373 132.923 23.1832 132.183 23.1832C131.402 23.1832 130.765 23.3373 130.271 23.6456C129.778 23.9539 129.408 24.4471 129.162 25.1254C128.936 25.783 128.823 26.6771 128.823 27.8074C128.823 29.3694 129.09 30.5409 129.624 31.3219C130.179 32.0823 131.032 32.4625 132.183 32.4625Z"
          fill="#EF4138"
        />
        <path
          d="M143.338 35.6687V21.7034C143.626 21.4568 144.139 21.1793 144.879 20.871C145.619 20.5422 146.462 20.2545 147.407 20.0078C148.373 19.7407 149.339 19.6071 150.305 19.6071C151.271 19.6071 152.032 19.679 152.586 19.8229C153.141 19.9667 153.563 20.1517 153.85 20.3778V23.7381C153.501 23.6559 153.059 23.5942 152.525 23.5531C151.99 23.512 151.436 23.4915 150.86 23.4915C150.285 23.4709 149.74 23.4709 149.226 23.4915C148.712 23.512 148.281 23.5531 147.931 23.6148V35.6687H143.338Z"
          fill="#EF4138"
        />
      </svg>
    </>
  );
};

export default Xelerator;
