/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import Button from "../Common/Button";
import WorkWithShakib from "../Common/WorkWithShakib";
import { successStories } from "../FackData";
const SuccessStories = () => {
  const [showData, setShowData] = useState(true);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div id="case">
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        className="modal-main-area-wrap"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WorkWithShakib handleClose={handleClose} />
        </Modal.Body>
      </Modal>
      <Container>
        <div className="success-stories-inner-area">
          <div className="success-stories-title">
            <div className="stories-title-left common-title">
              <h2>Knitting Stories</h2>
              <p>Connecting dots in Human, Retail and Technology</p>
            </div>
          </div>
          <div className="success-stories-lists-area-wrap">
            {successStories.map((item, idx) => (
              <div
                key={idx}
                className={
                  showData
                    ? `success-story-single-item ${item.visibleClass}`
                    : `success-story-single-item `
                }
              >
                <div className="total-count-demo-users">
                  <span>
                    {/* <span>
                      <CountUp end={item.count} duration={6} />
                    </span> */}
                  </span>
                </div>
                <div>
                  <div className="success-story-item-header">{item.logo}</div>
                  <div className="story-index">
                    <h3>{item.idxIcon}</h3>
                  </div>
                  <div className="mb-3">
                    {/* <h5>{item.title}</h5> */}
                    <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                  </div>
                </div>
                <div className="hero-button-bottom-wrap  story-button-bottom-btn">
                  <button className="maac-portfolio-btn demo-request-btn">
                    <a href={item.link} target="_blank" rel="noreferrer">
                      View Details
                    </a>
                  </button>
                  <button className="maac-portfolio-btn hero-button-two">
                    <a href={item.demoLink} target="_blank" rel="noreferrer">
                      Demo Request
                    </a>
                  </button>{" "}
                </div>
              </div>
            ))}
            {showData ? (
              <button
                className="story-show-button-bottom-show-more"
                onClick={() => setShowData(!showData)}
              >
                Show more
              </button>
            ) : (
              <button
                className="story-show-button-bottom-show-more"
                onClick={() => setShowData(!showData)}
              >
                Hide
              </button>
            )}
          </div>
          <div className="stories-bottom-contact-area">
            <div className="stories-left">
              <a onClick={handleShow}>
                <Button>Work With Nazmus</Button>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SuccessStories;
