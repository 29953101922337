import CBSLogo from "../assets/images/alumni/cbs.png";
import certifiedLogo from "../assets/images/alumni/certified.png";
import cimLogo from "../assets/images/alumni/cim.png";
import DULogo from "../assets/images/alumni/dhaka.png";
import iubLogo from "../assets/images/alumni/iub.png";
import meleborneLogo from "../assets/images/alumni/meleborne.png";
import pmiLogo from "../assets/images/alumni/pmi.png";
import cemex from "../assets/images/training/Cemex_logo.svg";
import Monitor from "../assets/images/training/Monitor.svg";
import batLogo from "../assets/images/training/bat.svg";
import cmcLogo from "../assets/images/training/cmc_logo_2.png";
import growthLogo from "../assets/images/training/growth.svg";
import insee from "../assets/images/training/insee_logo_en.svg";
import McKinsey from "../assets/images/training/mckinsey-companys.svg";
import richard_prev_ui from "../assets/images/training/richard_prev_ui 1.svg";
export const alumniData = [
  {
    logo: CBSLogo,
    title: "Masters of Science in Economics and Business Administration",
    subTitles: "Copenhagen Business School",
    subject: "International Business/Marketing",
    year: "2013",
  },
  {
    logo: meleborneLogo,
    title: "Masters of Business Administration ( Exchange Sem)",
    subTitles: "Melbourne Business School",
    subject: "Marketing",
    year: "2012",
  },

  {
    logo: DULogo,
    title: "Bachelor of Business Administration",
    subTitles: "University of Dhaka",
    subject: "Management",
    year: "2007",
  },
];
export const ProfessionalAccreditationData = [
  {
    logo: cmcLogo,
    title: "Member",
    subTitles: "Melbourne Business School",
    subject: "Marketing",
    year: "2023",
    visibleClass: "showData",
  },

  {
    logo: certifiedLogo,
    title: "Sales Master Certified <br /> Professional (SMCP)",
    subTitles: "Melbourne Business School",
    subject: "Marketing",
    year: "2022",
    visibleClass: "showData",
  },
  {
    logo: pmiLogo,
    title: "PMP",
    subTitles: "Copenhagen Business School",
    subject: "International Business/Marketing",
    year: "2019",
  },
  {
    logo: cimLogo,
    title: "MCIM <br /> Member, CIM",
    subTitles: "University of Dhaka",
    subject: "Management",
    year: "2016",
  },

  {
    logo: iubLogo,
    title: "Adjunct Faculty <br /> School of Business",
    subTitles: "Melbourne Business School",
    subject: "Marketing",
    year: "2013-16",
    visibleClass: "showData",
  },
];
export const trainingData = [
  {
    logo: growthLogo,
    title: "Management Consulting cohort",
    year: "2023",
    visibleClass: "showData",
  },
  {
    logo: growthLogo,
    title: "Growth hacking cohort",
    year: "2023",
    visibleClass: "showData",
  },
  {
    logo: cimLogo,
    title: "Copywriting",
    year: "2022",
    visibleClass: "showData",
  },
  {
    logo: McKinsey,
    title: "Post Merger Organizational Alignment & Strategic repositioning",
    year: "2016",
  },
  {
    logo: richard_prev_ui,
    title: "Brand Asset and positioning",
    year: "2016",
  },
  {
    logo: cemex,
    title: "Common Commercial Language",
    year: "2016",
    visibleClass: "showData",
  },
  {
    logo: insee,
    title: "Provincial Analysis",
    year: "2016",
    visibleClass: "showData",
  },
  {
    logo: Monitor,
    title: "Wining Habits",
    year: "2015",
    visibleClass: "showData",
  },

  {
    logo: CBSLogo,
    title: "Supply Chain excellence",
    year: "2012",
    visibleClass: "showData",
  },

  {
    logo: DULogo,
    title: "Qualitative research with SPSS",
    year: "2011",
    visibleClass: "showData",
  },
  {
    logo: batLogo,
    title: "Marketing Excellence Series",
    year: "2009",
    visibleClass: "showData",
  },
];
