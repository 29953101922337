import React from "react";
import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import Achievements from "../../Components/Achievements";
import Alumni from "../../Components/Alumni";
import ExperienceNew from "../../Components/ExperienceNew";
import Hero from "../../Components/Hero";
import HeroTwo from "../../Components/HeroTwo";
import MajorBrand from "../../Components/MajorBrand";
import PortfolioTwoCents from "../../Components/PortfolioTwoCents";
import SuccessStories from "../../Components/SuccessStories";
import Testimonials from "../../Components/Testimonials";
import TrainingAndResearchExp from "../../Components/TrainingAndResearchExp";
import YourNeed from "../../Components/YourNeed/YourNeed";
import shape from "../../assets/images/shape.png";
const HomePage = () => {
  return (
    <>
      <div className="shape-from-right">
        <img src={shape} alt="" />
      </div>
      <Header />
      <HeroTwo />
      <Hero />

      <Achievements />
      <SuccessStories />
      <Alumni />
      <TrainingAndResearchExp />
      <ExperienceNew />
      {/* <SkillsArea /> */}
      <MajorBrand />

      <YourNeed />
      <PortfolioTwoCents />
      <Testimonials />
      <Footer />
    </>
  );
};

export default HomePage;
