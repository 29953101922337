import React from "react";

const IconThree = () => {
  return (
    <>
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.2002 45.375V40.2"
          stroke="#292D32"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M30 45.375V35.025"
          stroke="#292D32"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M42.7998 45.375V29.825"
          stroke="#292D32"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M42.8002 14.625L41.6502 15.975C35.2752 23.425 26.7252 28.7 17.2002 31.075"
          stroke="#292D32"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M35.4751 14.625H42.8001V21.925"
          stroke="#292D32"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.5 55H37.5C50 55 55 50 55 37.5V22.5C55 10 50 5 37.5 5H22.5C10 5 5 10 5 22.5V37.5C5 50 10 55 22.5 55Z"
          stroke="#292D32"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default IconThree;
