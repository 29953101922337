/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  ProfessionalAccreditationData,
  alumniData,
  trainingData,
} from "../FackData/AlumniData";

const Alumni = () => {
  const [paShowData, setPaShowData] = useState(true);
  const [showData, setShowData] = useState(true);
  return (
    <div className="alumni-main-area-wrap">
      <Container>
        <div className="alumni-inner-area-wrap">
          <div className="alumni-title common-title">
            <h2>Alumni</h2>
            <p>
              Privileged and indebted to be a part of these global institutions,{" "}
              <br />
              especially teachers, who helped me see beyond the horizon
            </p>
          </div>
          <div className="alumni-content-area-wrap">
            <Row>
              {alumniData.map((item, idx) => (
                <Col lg="4" key={idx}>
                  <div className="alumni-single-content-item">
                    <img src={item.logo} alt="" />
                    <h4>{item.title}</h4>
                    <p>{item.subTitles}</p>
                    <p>
                      Major: <b>{item.subject}</b>
                    </p>
                    <b>Class of {item.year}</b>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
        <div className="alumni-inner-area-wrap professional-accreditation">
          <div className="alumni-title">
            <h4>Professional Accreditation</h4>
          </div>
          <div className="alumni-content-area-wrap">
            <Row>
              {ProfessionalAccreditationData.map((item, idx) => (
                <Col lg="4" key={idx}>
                  <div
                    className={
                      paShowData
                        ? `alumni-single-content-item  pa${item.visibleClass}`
                        : `alumni-single-content-item `
                    }
                  >
                    <img src={item.logo} alt="" />
                    <span>
                      <h4 dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                      <span>{item.year}</span>
                    </span>
                  </div>
                </Col>
              ))}
              <div className="px-2">
                {paShowData ? (
                  <button
                    className="story-show-button-bottom-show-more"
                    onClick={() => setPaShowData(!paShowData)}
                  >
                    Show more
                  </button>
                ) : (
                  <button
                    className="story-show-button-bottom-show-more"
                    onClick={() => setPaShowData(!paShowData)}
                  >
                    Hide
                  </button>
                )}
              </div>
            </Row>
          </div>
        </div>
        <div className="alumni-inner-area-wrap professional-accreditation">
          <div className="alumni-title">
            <h4>Training and Certifications</h4>
          </div>
          <div className="alumni-content-area-wrap">
            <Row>
              {trainingData.map((item, idx) => (
                <Col lg="4" md="6" key={idx}>
                  <div
                    className={
                      showData
                        ? `alumni-single-content-item training-certification ${item.visibleClass}`
                        : `alumni-single-content-item training-certification`
                    }
                  >
                    <h4
                      dangerouslySetInnerHTML={{ __html: item.title }}
                      className="mb-1"
                    ></h4>
                    <span className="mb-3">{item.year}</span>
                    <img src={item.logo} alt="" />
                  </div>
                </Col>
              ))}
              <div className="px-2">
                {showData ? (
                  <button
                    className="story-show-button-bottom-show-more"
                    onClick={() => setShowData(!showData)}
                  >
                    Show more
                  </button>
                ) : (
                  <button
                    className="story-show-button-bottom-show-more"
                    onClick={() => setShowData(!showData)}
                  >
                    Hide
                  </button>
                )}
              </div>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Alumni;
