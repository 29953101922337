import React from "react";

const IconTwo = () => {
  return (
    <>
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.75 45H10.3751C6.80006 45 5 43.1999 5 39.6249V10.3751C5 6.80006 6.80006 5 10.3751 5H21.125C24.7 5 26.4999 6.80006 26.4999 10.3751V15"
          stroke="#292D32"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.425 21.05V48.95C43.425 52.975 41.425 55 37.4 55H22.8C18.775 55 16.75 52.975 16.75 48.95V21.05C16.75 17.025 18.775 15 22.8 15H37.4C41.425 15 43.425 17.025 43.425 21.05Z"
          stroke="#292D32"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.5 15V10.3751C33.5 6.80006 35.2999 5 38.8749 5H49.6249C53.1999 5 54.9999 6.80006 54.9999 10.3751V39.6249C54.9999 43.1999 53.1999 45 49.6249 45H43.4249"
          stroke="#292D32"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25 27.5H35"
          stroke="#292D32"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25 35H35"
          stroke="#292D32"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30 55V47.5"
          stroke="#292D32"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default IconTwo;
