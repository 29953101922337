export const shakibData = [
  {
    title: "Discover",
    content: "The next blue ocean",
    background:
      "https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1120&q=80",
  },
  {
    title: "Develop",
    content: " The next best momentum",
    background:
      "https://images.unsplash.com/photo-1480506132288-68f7705954bd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1220&q=80",
  },
  {
    title: "Devise",
    content: "Your Signature move",
    background:
      "https://staging-ddc.sgp1.digitaloceanspaces.com/1672747049357-image-1.jpeg",
  },
  {
    title: "Design",
    content: "The next best mold",
    background:
      "https://images.unsplash.com/photo-1501959181532-7d2a3c064642?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1193&q=80",
  },
];

// https://staging-ddc.sgp1.digitaloceanspaces.com/1672747049357-image-1.jpeg
//https://staging-ddc.sgp1.digitaloceanspaces.com/1672747089121-images-2.jpeg
