function ArrowNext(props) {
  const { onClick } = props;
  return (
    <div className="arrow-icon-next">
      <svg
        onClick={onClick}
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="18"
          cy="18"
          r="17.5"
          transform="rotate(180 18 18)"
          fill="#ffffff00"
          stroke="#1B3F73"
        />
        <path
          d="M15.7491 12.0595L20.6391 16.9495C21.2166 17.527 21.2166 18.472 20.6391 19.0495L15.7491 23.9395"
          stroke="#1B3F73"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
export default ArrowNext;
