import React from "react";

const StrategyInsights = () => {
  return (
    <>
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M53.4333 35C61.0167 35 64.1667 32.0833 61.3667 22.5167C59.4708 16.0708 53.9292 10.5292 47.4833 8.63333C37.9167 5.83333 35 8.98333 35 16.5667V24.9667C35 32.0833 37.9167 35 43.75 35H53.4333Z"
          stroke="#EB2027"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.3322 42.875C55.6197 56.3792 42.6697 66.1792 27.9405 63.7875C16.8864 62.0084 7.99053 53.1125 6.18219 42.0584C3.81969 27.3875 13.5614 14.4375 27.0072 11.6959"
          stroke="#EB2027"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default StrategyInsights;
