import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import ArrowNext from "../Common/SlickSlider/ArrowNext";
import ArrowPrev from "../Common/SlickSlider/ArrowPrev";
import { experianceData } from "../FackData/experiance";
import BudgetIcon from "./img/BudgetIcon";
import PeopleIcon from "./img/PeopleIcon";
import RoleIconEx from "./img/RoleIconEx";
const ExperienceNew = () => {
  var settings = {
    dots: false,
    nav: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <ArrowPrev />,
    nextArrow: <ArrowNext />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div className="experiance-main-area-wrap">
      <Container>
        <div className="experiance-section-area-wrap">
          <div className="experiance-section-title common-title">
            <h2>Professional Experience</h2>
            <p>
              Helped these brands create better values, and make signature
              impact
            </p>
          </div>
          <div className="experiance-inner-area-wrap">
            <Slider {...settings}>
              {experianceData?.map((data, idx) => (
                <div key={idx} className="single-experiance-card-wrap">
                  <div className="single-experiance-card-header">
                    <div className="experiance-left-logo">
                      <img src={data?.logo} alt="" />
                    </div>
                    <div className="experiance-right-details">
                      <p dangerouslySetInnerHTML={{ __html: data.company }}></p>
                      <span>{data?.year}</span>
                    </div>
                  </div>
                  <div className="single-experiance-card-body">
                    <div className="experiace-dec first-dec-area-height">
                      <span className="experience-title">
                        <b>{data?.title}</b>
                      </span>
                      <p>
                        <RoleIconEx />
                        <span>
                          Role:
                          <b> {data.Role}</b>
                        </span>
                      </p>{" "}
                    </div>
                    <div className="hr-line-dec-area">
                      <hr />
                    </div>
                    <div className="experiace-dec">
                      <p>
                        <BudgetIcon />
                        <div>
                          Budget responsibilities:
                          <b> {data.Budget}</b>
                        </div>
                      </p>{" "}
                      <p>
                        <PeopleIcon />
                        <div>
                          People management:
                          <b> {data.people}</b>
                        </div>
                      </p>
                    </div>
                    <div className="hr-line-dec-area">
                      <hr />
                    </div>
                    <ul>
                      {data?.details?.map((list, idx) => (
                        <li key={idx}>{list.list}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ExperienceNew;
