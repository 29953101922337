import React from "react";
import { Container } from "react-bootstrap";
import CountUp from "react-countup";
import IconFive from "../assets/images/icon/achievements/IconFive";
import IconFour from "../assets/images/icon/achievements/IconFour";
import IconOne from "../assets/images/icon/achievements/IconOne";
import IconThree from "../assets/images/icon/achievements/IconThree";
import IconTwo from "../assets/images/icon/achievements/IconTwo";
const Achievements = () => {
  return (
    <div id="Journey">
      <Container>
        <div className="my-achievernts-inner-wrap">
          <div className="my-achievements-title ">
            <div className="common-title">
              <h2>Journey so far</h2>
            </div>
          </div>
          <div className="achievement-statistics">
            <div className="achievements-combo-data">
              <div className="achievements-single-item">
                <div className="achivements-icon">
                  <IconOne />
                </div>
                <h3>
                  <CountUp end="15" duration={4} /> +
                </h3>
                <h6>Years of Experience</h6>
              </div>{" "}
              <div className="achievements-single-item">
                <div className="achivements-icon">
                  <IconTwo />
                </div>
                <h3>
                  <CountUp end="7" duration={4} /> +
                </h3>
                <h6>Industry Exposure</h6>
              </div>{" "}
            </div>
            <div className="achievements-single-item">
              <div className="achivements-icon">
                <IconThree />
              </div>
              <h3>
                <CountUp end="3" duration={4} />
              </h3>
              <h6>Major Brand Launch</h6>
            </div>{" "}
            <div className="achievements-single-item">
              <div className="achivements-icon">
                <IconFour />
              </div>
              <h3>
                <CountUp end="15" duration={4} /> +
              </h3>
              <h6>Digital Platforms</h6>
            </div>{" "}
            <div className="achievements-single-item">
              <div className="achivements-icon">
                <IconFive />
              </div>
              <h3>
                <CountUp end="200" duration={4} /> h
              </h3>
              <h6>Hours Sales Training</h6>
            </div>
            {/* {achivementData.map((achievement, idx) => (
              <div className="achievements-single-item" key={idx}>
                <div className="achivements-icon">{achievement.icon}</div>
                <h3>
                  <CountUp end={achievement.count} />
                  {achievement.symble}
                </h3>
                <h6>{achievement.title}</h6>
              </div>
            ))} */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Achievements;
