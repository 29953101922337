import React from "react";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom/dist";
import "./App.css";
import Wrapper from "./Common/Wrapper";
import CaseStudies from "./Pages/Home/CaseStudies";
import HomePage from "./Pages/Home/HomePage";

function App() {
  return (
    <div className="App">
      <Wrapper>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/case-studies" element={<CaseStudies />} />
        </Routes>
      </Wrapper>
    </div>
  );
}

export default App;
