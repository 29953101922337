import React from "react";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
const ResponsiveNavItems2 = ({ toggleClass }) => {
  // const scrollToTop = () => {
  //   scroll.scrollToTop();
  // };
  return (
    <div className={`responsive-nav-items ${toggleClass}`}>
      <Container>
        <ul>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <Link to="blog" smooth={true} offset={-50} duration={500}>
              Blog
            </Link>
          </li>
          <li>
            <Link to="case" smooth={true} offset={-50} duration={500}>
              Case Studies
            </Link>
          </li>
          {/* <li className="desktop-main-logo">
          <Link>
            <h3>Logo</h3>
          </Link>
        </li> */}
          <li>
            <Link to="podcast" smooth={true} offset={-50} duration={500}>
              Podcast
            </Link>
          </li>
          <li>
            <Link to="books" smooth={true} offset={-50} duration={500}>
              Books
            </Link>
          </li>
          <li>
            <Link to="works" smooth={true} offset={-50} duration={500}>
              Works
            </Link>
          </li>
        </ul>
      </Container>
    </div>
  );
};

export default ResponsiveNavItems2;
