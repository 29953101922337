function ArrowPrev(props) {
  const { onClick } = props;
  return (
    <div className="arrow-icon-prev">
      <svg
        onClick={onClick}
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18" cy="18" r="17.5" fill="#ffffff00" stroke="#1B3F73" />
        <path
          d="M20.2509 23.9405L15.3609 19.0505C14.7834 18.473 14.7834 17.528 15.3609 16.9505L20.2509 12.0605"
          stroke="#1B3F73"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
export default ArrowPrev;
