import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { EmailSchema } from "../schema/EmailSchema";
import CustomInput from "./CustomInput";
import LoadingIcon from "./img/LoadingIcon";
import submitImage from "./img/submit-confirm-message.png";
const WorkWithShakib = ({ handleClose }) => {
  const [values, setValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const initialValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };
  // handleClose();
  const handlesubmit = (values) => {
    setLoading(true);
    axios
      .post(
        "https://maacsolutionsbackend-api.salesx-staging.xyz/api/v1/users/mailContent-forward",
        values
      )
      .then(function (response) {
        console.log(response);
        setLoading(false);
        setSuccess(true);
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log(values);
    // setValues(values);
  };
  return (
    <>
      {success ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div>
            <img
              src={submitImage}
              alt=""
              style={{ width: "100px", margin: "30px auto", display: "block" }}
            />
            <h6 style={{ marginBottom: "30px" }}>
              You have successfully submitted <br /> your request
            </h6>
            <Button
              className="form-submit-btn"
              type="button"
              style={{
                padding: "10px 16px",
                backgroundColor: "#1b3f73",
              }}
              onClick={() => handleClose()}
            >
              Close
            </Button>
          </div>
        </div>
      ) : (
        <div className="expertise-popup-modal-content-wrap">
          <h4>Light your next venture with us</h4>
          <p>Connect with us to grow and create a symphony of disruptions. </p>
          <Formik
            initialValues={values || initialValues}
            validationSchema={EmailSchema}
            onSubmit={handlesubmit}
            enableReinitialize={true}
          >
            {({ values, errors, isSubmitting }) => (
              <Form className="from-group contact-form-data-get mt-4 ">
                {/* {console.log('values', values, errors)} */}
                <Field
                  name="name"
                  label={"Name"}
                  type="text"
                  placeholder="Type your name"
                  component={CustomInput}
                />{" "}
                <Field
                  name="email"
                  label={"E-mail"}
                  type="email"
                  placeholder="Type your e-mail"
                  component={CustomInput}
                />{" "}
                <Field
                  name="subject"
                  label={"Subject"}
                  type="text"
                  placeholder="subject"
                  component={CustomInput}
                />{" "}
                <div>
                  <label className="form-label" htmlFor="">
                    Message
                  </label>
                  <Field
                    name="message"
                    as="textarea"
                    placeholder="Type your Message"
                    className="form-control mb-3"
                  />
                </div>
                {loading ? (
                  <Button
                    className="form-submit-btn btn btn-secondary"
                    type={loading ? "button" : "button"}
                    // disabled={true}
                    style={{
                      padding: "4px",
                      width: "100%",
                      cursor: "no-drop",
                    }}
                  >
                    <LoadingIcon />
                  </Button>
                ) : (
                  <Button
                    className="form-submit-btn btn btn-secondary"
                    type="submit"
                    style={{ padding: "10px 16px", width: "100%" }}
                  >
                    Submit
                  </Button>
                )}
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default WorkWithShakib;
