import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { animateScroll as scroll, Link } from "react-scroll";
const Header = () => {
  const [navToggle, setNavToggle] = useState(false);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div className="header-main-area-wrap" id="hero">
      <Container>
        {/* <div className="header-top-main-area">
          <ul>
            <li>shakib@maacsolutions.com</li>
            <li>+123 456 789</li>
          </ul>
        </div> */}
        <div className="header-bottom-area-wrap">
          <div className="header-logo-area-left">
            <Link
              to="hero"
              onClick={() => {
                scrollToTop();
                setTimeout(() => {
                  setNavToggle(false);
                }, 550);
              }}
            >
              <h4>Nazmus Shakib</h4>
            </Link>

            {/* <img
              src={logo}
              alt=""
              onClick={() => {
                scrollToTop();
                setTimeout(() => {
                  setNavToggle(false);
                }, 550);
              }}
            /> */}
          </div>
          <div className="header-navbar-items-area">
            <div className="nav-toggle-button-wrap">
              <div className="footer-right-social-area">
                <a
                  href="https://twitter.com/MdNazmusShakib"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bx bxl-twitter"></i>
                </a>
                <a
                  href="https://www.linkedin.com/in/mdshakib/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bx bxl-linkedin-square"></i>
                </a>
              </div>{" "}
              <button
                onClick={() => setNavToggle(!navToggle)}
                className={
                  navToggle
                    ? "nav-toggle-menu show-nav-button"
                    : "nav-toggle-menu hide-nav-button"
                }
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>

            <div
              className={`responsive-nav-items ${
                navToggle ? "nav-toggle-menu-show" : "nav-toggle-menu-hide"
              }`}
            >
              <Container>
                <ul>
                  <li>
                    <Link
                      to="hero"
                      onClick={() => {
                        scrollToTop();
                        setTimeout(() => {
                          setNavToggle(false);
                        }, 550);
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="Journey"
                      smooth={true}
                      offset={-50}
                      duration={500}
                      onClick={() => {
                        setTimeout(() => {
                          setNavToggle(false);
                        }, 550);
                      }}
                    >
                      Journey so far
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="case"
                      smooth={true}
                      offset={-50}
                      duration={500}
                      onClick={() => {
                        setTimeout(() => {
                          setNavToggle(false);
                        }, 550);
                      }}
                    >
                      Case Studies
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="Expertise"
                      smooth={true}
                      offset={-50}
                      duration={500}
                      onClick={() => {
                        setTimeout(() => {
                          setNavToggle(false);
                        }, 550);
                      }}
                    >
                      Expertise
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="Blog"
                      smooth={true}
                      offset={-50}
                      duration={500}
                      onClick={() => {
                        setTimeout(() => {
                          setNavToggle(false);
                        }, 550);
                      }}
                    >
                      Blog
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="works"
                      smooth={true}
                      offset={-50}
                      duration={500}
                      // onClick={() => {
                      //   // setNavToggle(false);

                      // }}
                    >
                      Works
                    </Link>
                  </li> */}
                </ul>
              </Container>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Header;
