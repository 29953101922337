import React from "react";
import { Container } from "react-bootstrap";
import TruncateMarkup from "react-truncate-markup";
import { Autoplay, Lazy, Pagination } from "swiper";
import "swiper/css/pagination";
import "swiper/css/parallax";
import { Swiper, SwiperSlide } from "swiper/react";
import "../assets/scss/responsive.scss";
import { testimonialsData } from "../FackData/testimonialsData";
const Testimonials = () => {
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  return (
    <>
      <div className="testimonials-main-area-wrap">
        <Container>
          <div className="testimonials-header common-title">
            <h2>
              How's the working experience <br /> with Nazmus
            </h2>
          </div>

          <div className="testimonials-items-wrap">
            <Swiper
              modules={[Autoplay, Lazy, Pagination]}
              spaceBetween={30}
              slidesPerView={3}
              autoplay={true}
              loop={true}
              centeredSlides={true}
              pagination={{ clickable: true }}
              breakpoints={{
                300: {
                  // width: 576,
                  slidesPerView: 1,
                  centeredSlides: false,
                },
                576: {
                  // width: 576,
                  slidesPerView: 2,
                  centeredSlides: false,
                },
                769: {
                  // width: 769,
                  slidesPerView: 3,
                  centeredSlides: true,
                },
              }}
            >
              {testimonialsData.map((item, idx) => (
                <SwiperSlide key={idx}>
                  {({ isActive }) => (
                    <div className={`testimonials-single-item`} key={idx}>
                      <div className="testimonials-single-item-bottom-content">
                        {item.icon}
                        <TruncateMarkup lines={3}>
                          <p>{item.content}</p>
                        </TruncateMarkup>
                      </div>
                      <div className="profile-info-bottom">
                        <img src={item.profileImage} alt="" />
                        <h4>{item.name}</h4>
                        <p
                          dangerouslySetInnerHTML={{ __html: item.designation }}
                        ></p>
                      </div>
                    </div>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Testimonials;
