import React from "react";

const IconOne = () => {
  return (
    <>
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.375 41.25V46.5"
          stroke="#0B2E4E"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.875 55H42.875V52.5C42.875 49.75 40.625 47.5 37.875 47.5H22.875C20.125 47.5 17.875 49.75 17.875 52.5V55V55Z"
          stroke="#0B2E4E"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M15.375 55H45.375"
          stroke="#0B2E4E"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30 40C20.325 40 12.5 32.175 12.5 22.5V15C12.5 9.475 16.975 5 22.5 5H37.5C43.025 5 47.5 9.475 47.5 15V22.5C47.5 32.175 39.675 40 30 40Z"
          stroke="#0B2E4E"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.6751 29.125C11.8001 28.525 10.1501 27.425 8.8501 26.125C6.6001 23.625 5.1001 20.625 5.1001 17.125C5.1001 13.625 7.8501 10.875 11.3501 10.875H12.9751C12.4751 12.025 12.2251 13.3 12.2251 14.625V22.125C12.2251 24.625 12.7501 26.975 13.6751 29.125Z"
          stroke="#0B2E4E"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.3252 29.125C48.2002 28.525 49.8502 27.425 51.1502 26.125C53.4002 23.625 54.9002 20.625 54.9002 17.125C54.9002 13.625 52.1502 10.875 48.6502 10.875H47.0252C47.5252 12.025 47.7752 13.3 47.7752 14.625V22.125C47.7752 24.625 47.2502 26.975 46.3252 29.125Z"
          stroke="#0B2E4E"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default IconOne;
