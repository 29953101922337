import React from "react";

const SIcon3 = () => {
  return (
    <>
      <svg
        width="212"
        height="316"
        viewBox="0 0 212 316"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M103.465 316C87.9454 316 73.1442 312.91 59.0614 306.731C44.9786 300.408 32.6921 291.571 22.2019 280.218C11.8554 268.866 4.45475 255.502 0 240.126L56.9059 225.037C59.9236 236.389 65.8154 245.227 74.5812 251.55C83.4907 257.729 93.1187 260.819 103.465 260.819C112.375 260.819 120.494 258.663 127.823 254.352C135.295 250.041 141.187 244.221 145.498 236.892C149.953 229.563 152.18 221.444 152.18 212.535C152.18 199.171 147.582 187.746 138.385 178.262C129.332 168.634 117.692 163.82 103.465 163.82C99.1542 163.82 94.9868 164.467 90.9632 165.76C86.9395 166.91 83.0596 168.562 79.3233 170.718L53.0259 124.589L151.749 42.6794L156.06 55.1815H1.07094V0H206.068V55.1815L129.332 129.332L128.9 112.95C146.719 115.68 161.736 121.931 173.951 131.703C186.309 141.331 195.65 153.258 201.973 167.484C208.439 181.567 211.673 196.584 211.673 212.535C211.673 232.078 206.715 249.682 196.799 265.345C186.884 280.865 173.735 293.223 157.353 302.42C140.971 311.473 123.009 316 103.465 316Z"
          fill="#9E9E9E"
          fillOpacity="0.16"
        />
      </svg>
    </>
  );
};

export default SIcon3;
